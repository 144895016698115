<!-- 节制闸详情 -->
<template>
  <div class="app">
    <div class="StationOne">
      <!-- 拨号 -->
      <div class="rightcebian">
        <div class="youce" @click="shouqiClick">
          <i class="el-icon-phone-outline"></i>
        </div>
        <div class="rightOne" v-if="shouqi">
          <div v-if="shouqiflag">
            <div class="selctedclas">
              <el-select style="width: 84%" v-model="optionValue" @change="cutGatelist">
                <el-option v-for="item in optionsList" :key="item.talkbackNumber" :label="item.siteName"
                  :value="item.talkbackNumber">
                </el-option>
              </el-select>
            </div>
            <div class="banclass">
              <iframe id="showIframe" allow="microphone;camera;midi;encrypted-media;" ref="iframeShow" frameborder="0"
                name="showHere" scrolling="no" :src="urlSrc" @load="loaded"></iframe>
            </div>
          </div>
          <div style="display: flex;
              justify-content: center;
              align-items: center;
              line-height: 120px;
              font-size: 17px;
            " v-else>
            正在挂断请等待
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="StationOne-left">
          <!--四干二号  -->
          <div class="FourDo Card-b-c">
            <Titles :Titles="siteName" />
            <!-- 动画区域 -->
            <div class="animation">
              <Anmation :gateNum="Number(valveForm.value) ? Number(valveForm.value) : 0" :gateList="options"
                :gateHeight="Number(gateVo.gateHeight)" :gateHeightLimit="Number(gateVo.gateHeightLimit)"
                :gateShow="gateShow"></Anmation>
            </div>
            <!-- 阀门区域 -->
            <div class="valve">
              <el-form :rules="rules" ref="ruleForm" v-model="valveForm">
                <!-- 阀门 -->
                <el-form-item label="闸门" class="update1 flex valve-h flex-a-c">
                  <el-select v-model="valveForm.value" style="width: 30%" @change="cutGate">
                    <el-option v-for="item in options" :key="item.id" :label="item.gateName" :value="item.id">
                    </el-option>
                  </el-select>
                  <span style="padding-left: 10px;width:15%" class="Font_style1">状态：</span>
                  <span style="color: green;width:15%" class="Font_style1"
                    v-if="siteItemVo.deviceStatus == 0 && gateVo.gateHeight > 5">运行中</span>
                  <span v-else-if="
                    siteItemVo.deviceStatus === 0 && gateVo.gateHeight <= 5
                  " style="color: red;width:15%" class="Font_style1">停止</span>
                  <span style="color: gray;width:15%" v-if="siteItemVo.deviceStatus == 1" class="Font_style1">离线</span>

                  <span style="padding-left: 45px; font-size: 18px;width:19%" class="Font_style1">控制：</span>
                  <span style="color: black; font-size: 18px;width:15%" v-if="siteItemVo.longRange == 0">本地</span>
                  <span style="color: #00a0e9; font-size: 18px;width:15%" v-if="siteItemVo.longRange == 1">远程</span>
                  <!--                  <span v-if="siteItemVo.deviceStatus === 0 && gateVo.gateHeight > 5" style="color: green">运行中</span>-->
                  <!--                  <span v-else-if="siteItemVo.deviceStatus === 0 && gateVo.gateHeight <= 5" style="color: red">停止</span>-->
                  <!--                  <span v-else style="color: gray">离线</span>-->
                </el-form-item>
                <!-- 修改闸门高度 -->
                <el-form-item label="修改闸门高度" class="update flex valve-h flex-a-c">
                  <el-input v-model="valveForm.valveHeight" maxlength="3" />
                  <div style="
                      margin-left: calc(10 / 1920 * 100vw);
                      font-size: 18px;
                      font-weight: 600;
                    ">
                    (cm)
                  </div>
                  <div class="flex flex-a-c" style="margin-left: calc(16 / 1920 * 100vw)">
                    <el-button type="primary" @click="startSluice(1)">开启
                    </el-button>
                    <el-button @click="stopSluice(2)" style="margin: 0 5px"
                      :disabled="siteItemVo.deviceStatus === 0 ? false : true">关闭
                    </el-button>
                    <el-button @click="stopSluice(3)" :disabled="siteItemVo.deviceStatus === 0 ? false : true">急停
                    </el-button>
                    <!-- <el-button type="primary" style="margin-left: 10px" @click="startSluice(1)">启动
                    </el-button>
                    <el-button @click="stopSluice(2)" style="margin-left: 65px">关闭</el-button> -->
                    <!-- <el-button @click="stopSluice(3)">停止</el-button> -->
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- 闸门信息 -->
          <div class="strobe Card-b-c" style="position: relative">
            <Titles1 style="position: relative" Titles="闸门信息" />
            <div class="update font-s-20">
              <span class="font-s-16">更新时间：</span><span class="textUpdate">{{ currentDatadataTime }}</span>
            </div>
            <!--            {{updateTime}}-->
            <div class="strobeBox flex flex-a-c">
              <!-- 闸门高度 -->
              <div class="strobeBox-height flex flex-a-c font-w-b">
                <span class="font-s-18">闸门高度：</span>
                <span class="font-s-20">{{ gateVo.gateHeight }}</span>
                <span class="font-s-18">(cm)</span>
              </div>
              <!-- 闸门限高 -->
              <div class="strobeBox-headroom flex flex-a-c font-w-b">
                <span class="font-s-18">闸门限高：</span>
                <span class="font-s-20">{{ gateVo.gateHeightLimit }}</span>
                <span class="font-s-18">(cm)</span>
              </div>
            </div>
          </div>
          <!-- 水文，太阳能 -->
          <div class="StationOne-left-bottom">
            <div class="content flex flex-j-s-b">
              <!-- 左侧 -->
              <div class="left">
                <!-- 水文信息 -->
                <div class="hydrology Card-b-c" v-show="siteItemVo.id != 92">
                  <Titles Titles="水文信息" />
                  <div class="hydrologyBox">
                    <!-- 水位闸前 -->
                    <div class="warningWater flex flex-a-c">
                      <span class="font-s-16">闸前水位：</span>
                      <span class="font-s-18 font-w-b" :style="{
                        color:
                          siteItemVo.gateLevel > siteItemVo.warningLevel
                            ? 'red'
                            : '',
                      }">{{ siteItemVo.gateLevel }}</span>
                      <span class="font-s-16">(cm)</span>
                    </div>
                    <!-- 当前水位 闸后水位 -->
                    <div class="warningWater flex flex-a-c">
                      <span class="font-s-16">闸后水位：</span>
                      <span class="font-s-18 font-w-b">
                        {{ siteItemVo.currentLevel }}
                      </span>
                      <span class="font-s-16">(cm)</span>
                    </div>

                    <!-- 警戒水位 -->
                    <div class="warningWater flex flex-a-c">
                      <span class="font-s-16">警戒水位：</span>
                      <span class="font-s-18 font-w-b table-num">
                        <el-input id="input1" @blur="test" maxlength="4" v-model="warningLevel"></el-input>
                      </span>
                      <span class="font-s-16">(cm)</span>
                    </div>

                    <!-- 瞬时流量 -->
                    <div class="warningWater flex flex-a-c">
                      <span class="font-s-16">瞬时流量：</span>
                      <span class="font-s-18 font-w-b">{{
                          siteItemVo.traffic
                      }}</span>
                      <span class="font-s-16">(m³/h)</span>
                    </div>
                    <!-- 流速 -->
                    <div class="warningWater flex flex-a-c">
                      <span class="font-s-16">流速：</span>
                      <span class="font-s-18 font-w-b">{{
                          siteItemVo.velocity
                      }}</span>
                      <span class="font-s-16">(m/s)</span>
                    </div>
                  </div>
                </div>

                <div class="hydrology Card-b-c" v-show="siteItemVo.id == 92">
                  <Titles Titles="水文信息" />
                  <div class="hydrologyBox">
                    <!-- 水位闸前 -->
                    <div class="warningWater flex flex-a-c">
                      <span class="font-s-16">闸前水位：</span>
                      <span class="font-s-18 font-w-b" :style="{
                        color:
                          siteItemVo.gateLevel > siteItemVo.warningLevel
                            ? 'red'
                            : '',
                      }">{{ siteItemVo.gateLevel }}</span>
                      <span class="font-s-16">(cm)</span>
                    </div>
                    <!-- 当前水位 闸后水位 -->
                    <div class="warningWater flex flex-a-c">
                      <span class="font-s-16">闸后水位：</span>
                      <span class="font-s-18 font-w-b">
                        {{ gateVo.currentLevel }}
                      </span>
                      <span class="font-s-16">(cm)</span>
                    </div>

                    <!-- 警戒水位 -->
                    <div class="warningWater flex flex-a-c">
                      <span class="font-s-16">警戒水位：</span>
                      <span class="font-s-18 font-w-b table-num">
                        <el-input id="input1" @blur="test" maxlength="4" v-model="warningLevel"></el-input>
                      </span>
                      <span class="font-s-16">(cm)</span>
                    </div>

                    <!-- 瞬时流量 -->
                    <div class="warningWater flex flex-a-c">
                      <span class="font-s-16">瞬时流量：</span>
                      <span class="font-s-18 font-w-b">{{
                          gateVo.traffic
                      }}</span>
                      <span class="font-s-16">(m³/h)</span>
                    </div>
                    <!-- 流速 -->
                    <div class="warningWater flex flex-a-c">
                      <span class="font-s-16">流速：</span>
                      <span class="font-s-18 font-w-b">{{
                          gateVo.velocity
                      }}</span>
                      <span class="font-s-16">(m/s)</span>
                    </div>
                  </div>
                </div>
                <!-- 市电信息 -->
                <div class="weather Card-b-c">
                  <Titles Titles="市电信息" />
                  <div class="weatherBox">
                    <div class="weatherItem flex flex-a-c">
                      <span class="font-s-16">B相电流：</span>
                      <span class="font-s-16">{{ siteItemVo.bCurrent }}</span>
                      <span class="font-s-16">(A)</span>
                    </div>
                    <div class="weatherItem flex flex-a-c">
                      <span class="font-s-16">B相电压：</span>
                      <span class="font-s-16">{{ siteItemVo.bVoltage }}</span>
                      <span class="font-s-16">(V)</span>
                    </div>
                  </div>
                </div>
                <!-- 暂时不要的 -->
                <div>
                  <!--                <div class="weather Card-b-c">-->
                  <!--                  <Titles Titles="气象信息"/>-->
                  <!--                  <div class="weatherBox font-w-b">-->
                  <!--                    <div class="weatherItem flex flex-a-c">-->
                  <!--                      <span class="font-s-18">风速：</span>-->
                  <!--                      <span class="font-s-18" v-if="siteItemVo.direction == 0 && siteItemVo.speed == 0">无</span>-->
                  <!--                      <span v-else class="font-s-18">{{ siteItemVo.direction }}风 {{ siteItemVo.speed }}</span>-->
                  <!--                      &lt;!&ndash; <span class="font-s-18">(m/s)</span> &ndash;&gt;-->
                  <!--                    </div>-->
                  <!--                    <div class="weatherItem flex flex-a-c">-->
                  <!--                      <span class="font-s-18">温度：</span>-->
                  <!--                      <span class="font-s-18">{{-->
                  <!--                          siteItemVo.atmosphericTemperature-->
                  <!--                        }}</span>-->
                  <!--                      <span class="font-s-18">(℃)</span>-->
                  <!--                    </div>-->
                  <!--                    <div class="weatherItem flex flex-a-c">-->
                  <!--                      <span class="font-s-18">湿度：</span>-->
                  <!--                      <span class="font-s-18">{{-->
                  <!--                          siteItemVo.atmosphericHumidity-->
                  <!--                        }}</span>-->
                  <!--                      <span class="font-s-18">(RH)</span>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  <div class="weatherImg">-->
                  <!--                    <p>当前降水量</p>-->
                  <!--                    <img src="../../assets/images/UseWater/wearther1.gif" alt="" v-if='siteItemVomm>0'/>-->
                  <!--                    <img src="../../assets/images/UseWater/wearther.png" alt="" v-else/>-->
                  <!--                    <p v-if="siteItemVomm==''">{{ siteItemVomm }}(mm)</p>-->
                  <!--                    <p v-else-if="siteItemVomm==''">0(mm)</p>-->
                  <!--                  </div>-->
                  <!--                </div>-->
                </div>
              </div>
              <!-- 右侧 -->
              <div class="right">
                <!-- 太阳能信息 -->
                <div class="solar Card-b-c" v-if="isMains == 0 ? true : false">
                  <Titles Titles="太阳能信息" />
                  <div class="solarBox">
                    <div class="solarItem flex flex-a-c">
                      <span class="font-s-16">光伏电压：</span>
                      <span class="font-s-16">{{
                          Number(siteItemVo.pvVoltage).toFixed(1)
                      }}</span>
                      <span class="font-s-16">(V)</span>
                    </div>
                    <div class="solarItem flex flex-a-c">
                      <span class="font-s-16">光伏电流：</span>
                      <span class="font-s-16">{{
                          Number(siteItemVo.pvCurrent).toFixed(1)
                      }}</span>
                      <span class="font-s-16">(A)</span>
                    </div>
                    <div class="solarItem flex flex-a-c">
                      <span class="font-s-16">电池温度：</span>
                      <span class="font-s-16">{{
                          Number(siteItemVo.batteryTemperature).toFixed(1)
                      }}</span>
                      <span class="font-s-16">(℃)</span>
                    </div>
                    <div class="solarItem flex flex-a-c">
                      <span class="font-s-16">电池电压：</span>
                      <span class="font-s-16">{{
                          Number(siteItemVo.batteryVoltage).toFixed(1)
                      }}</span>
                      <span class="font-s-16">(V)</span>
                    </div>
                    <div class="solarItem flex flex-a-c">
                      <span class="font-s-16">日发电量：</span>
                      <span class="font-s-16">{{
                          Number(siteItemVo.dailyOutput).toFixed(1)
                      }}</span>
                      <span class="font-s-16">(KWH)</span>
                    </div>
                    <div class="solarItem flex flex-a-c">
                      <span class="font-s-16">剩余电量：</span>
                      <span class="font-s-16">{{
                          Number(siteItemVo.soc).toFixed(1)
                      }}</span>
                      <span class="font-s-16">(%)</span>
                    </div>
                    <div class="solarItem flex flex-a-c">
                      <span class="font-s-16">负载电流：</span>
                      <span class="font-s-16">{{
                          Number(siteItemVo.loadCurrent).toFixed(1)
                      }}</span>
                      <span class="font-s-16">(A)</span>
                    </div>
                    <!--
                    <div class="solarItem flex flex-a-c ">
                      <span class="font-s-18">警戒电量：</span>
                      <el-input v-model="alertPower" @blur="getBluralertPower" />
                      <span class="font-s-18">(%)</span>
                    </div>
                     -->
                  </div>
                </div>

                <!-- 节制闸基本信息 -->
                <div class="isMains Card-b-c" v-else>
                  <Titles Titles="节制闸基本信息" />
                  <div class="isMainsBox">
                    <div class="isMainsItem flex flex-a-c">
                      <span class="font-s-16">站点名称：</span>
                      <!--                      <span class="font-s-18">{{ currentData.siteName }}</span>-->
                      <span class="font-s-16">{{ siteName }}</span>
                    </div>

                    <div class="isMainsItem flex flex-a-c">
                      <span class="font-s-16">建设时间：</span>
                      <span class="font-s-16">{{ datetimer }}</span>
                    </div>

                    <div class="isMainsItem flex flex-a-c">
                      <span class="font-s-16">责任人员：</span>
                      <span class="font-s-16">{{ currentData.head }}</span>
                    </div>

                    <div class="floterrousel" style="color: black">
                      <div class="centerlist">
                        <el-image style="width: 100%; height: 97%" :src="currentData.img"></el-image>
                        <!--                        <el-carousel height="110px">-->
                        <!--                          <el-carousel-item v-for="item in currentData" :key="item">-->
                        <!--                            -->
                        <!--                          </el-carousel-item>-->
                        <!--                        </el-carousel>-->
                      </div>
                      <!--                      <div class="leftMain">-->
                      <!--                        <div class="leftcarousel">-->
                      <!--                          <el-image-->
                      <!--                            style="width: 220px; height: 190px"-->
                      <!--                            :src="siteItemVow.img"-->
                      <!--                            :preview-src-list="srcList">-->
                      <!--                          </el-image>-->
                      <!--                        </div>-->
                      <!--                      </div>-->
                    </div>
                    <!-- 暂时不要 需要的话删除外层div -->
                    <div>
                      <!--                    <div class="isMainsItem flex flex-a-c">-->
                      <!--                      <span class="font-s-18">A相电流：</span>-->
                      <!--                      <span class="font-s-18">{{-->
                      <!--                          Number(siteItemVo.aCurrent).toFixed(1)-->
                      <!--                        }}</span>-->
                      <!--                      <span class="font-s-18">(A)</span>-->
                      <!--                    </div>-->
                      <!--                    <div class="isMainsItem flex flex-a-c">-->
                      <!--                      <span class="font-s-18">B相电流：</span>-->
                      <!--                      <span class="font-s-18">{{-->
                      <!--                          Number(siteItemVo.bCurrent).toFixed(1)-->
                      <!--                        }}</span>-->
                      <!--                      <span class="font-s-18">(A)</span>-->
                      <!--                    </div>-->

                      <!--                    <div class="isMainsItem flex flex-a-c">-->
                      <!--                      <span class="font-s-18">C相电流：</span>-->
                      <!--                      <span class="font-s-18">{{-->
                      <!--                          Number(siteItemVo.cCurrent).toFixed(1)-->
                      <!--                        }}</span>-->
                      <!--                      <span class="font-s-18">(A)</span>-->
                      <!--                    </div>-->
                      <!--                    <div class="isMainsItem flex flex-a-c">-->
                      <!--                      <span class="font-s-18">N相电流：</span>-->
                      <!--                      <span class="font-s-18">{{-->
                      <!--                          Number(siteItemVo.dCurrent).toFixed(1)-->
                      <!--                        }}</span>-->
                      <!--                      <span class="font-s-18">(A)</span>-->
                      <!--                    </div>-->
                      <!--                    <div class="isMainsItem flex flex-a-c">-->
                      <!--                      <span class="font-s-18">A相电压：</span>-->
                      <!--                      <span class="font-s-18">{{-->
                      <!--                          Number(siteItemVo.aVoltage).toFixed(1)-->
                      <!--                        }}</span>-->
                      <!--                      <span class="font-s-18">(V)</span>-->
                      <!--                    </div>-->
                      <!--                    <div class="isMainsItem flex flex-a-c">-->
                      <!--                      <span class="font-s-18">B相电压：</span>-->
                      <!--                      <span class="font-s-18">{{-->
                      <!--                          Number(siteItemVo.bVoltage).toFixed(1)-->
                      <!--                        }}</span>-->
                      <!--                      <span class="font-s-18">(V)</span>-->
                      <!--                    </div>-->
                      <!--                    <div class="isMainsItem flex flex-a-c">-->
                      <!--                      <span class="font-s-18">C相电压：</span>-->
                      <!--                      <span class="font-s-18">{{-->
                      <!--                          Number(siteItemVo.cVoltage).toFixed(1)-->
                      <!--                        }}</span>-->
                      <!--                      <span class="font-s-18">(V)</span>-->
                      <!--                    </div>-->
                    </div>
                  </div>
                </div>

                <div class="weather Card-b-c">
                  <Titles Titles="安防报警" />
                  <div class="weatherBox">
                    <div class="weatherItem flex flex-a-c">
                      <span class="font-s-16">门禁报警：</span>
                      <span style="font-size: 16px; font-weight: 600; color: red"
                        v-if="alarmStatus.accessControlStatus == 1" class="neirong">报警中</span>
                      <span style="font-size: 16px;font-weight: 600;color: #22ac38;" v-else class="neirong">正常</span>
                    </div>
                    <div class="weatherItem flex flex-a-c">
                      <span class="font-s-16">周界报警：</span>
                      <span style="font-size: 16px; font-weight: 600; color: red"
                        v-if="alarmStatus.perimeterStatus == 1" class="neirong">报警中</span>
                      <span style="font-size: 16px;font-weight: 600;color: #22ac38;" v-else class="neirong">正常</span>
                    </div>
                  </div>
                </div>
                <!-- 更新时间 -->
                <div>
                  <!--                  <div class="update Card-b-c font-s-18 t-x font-w-b">-->
                  <!--                    <p>更新时间</p>-->
                  <!--                    <span>{{siteItemVo.updateTime ? new Date(siteItemVo.updateTime).toLocaleString('chinese', {-->
                  <!--                        hour12: false,-->
                  <!--                        year: 'numeric',-->
                  <!--                        month: '2-digit',-->
                  <!--                        day: '2-digit',-->
                  <!--                      })-->
                  <!--                        .replace(/\//g, '-') +-->
                  <!--                      ' ' +-->
                  <!--                      new Date(siteItemVo.updateTime).toLocaleString('chinese', {-->
                  <!--                        hour12: false,-->
                  <!--                        hour: '2-digit',-->
                  <!--                        minute: '2-digit',-->
                  <!--                        second: '2-digit',-->
                  <!--                      })-->
                  <!--                      : siteItemVo.updateTime-->
                  <!--                      }}</span>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 监控视频 -->
        <div class="StationOne-right Card-b-c" @mouseover="videoMouseover" @mouseout="videoMousemove">
          <Titles Titles="监控视频" />
          <div class="video flex flex-wrap" v-loading="loading" element-loading-text="数据量过大，加载中...">
            <!-- 暂无数据 -->
            <div class="flex flex-a-c flex-j-c" style="width: 100%; height: 100%" v-if="total === 0">
              <img src="../../assets/images/index/video.png"
                style="width: calc(330 / 1920 * 100vw);height: calc(329 / 1080 * 100vh);" />
            </div>


            <!-- 监控视频区域 -->
            <div class="videoItem" v-else>
              <div class="btn-arrow"></div>
              <div class="box-video">
                <div class="box-caldjw">
                  <!-- 第一 -->
                  <div class="video111" v-if="videoList == undefined">
                    <div class="flex flex-a-c flex-j-c" style="width: 100%; height: 100%">
                      <img src="../../assets/images/index/video.png"
                        style="width: calc(330 / 1920 * 100vw);height: calc(329 / 1080 * 100vh);" />
                    </div>
                  </div>
                  <div v-else class="video111" id="video-1">
                    <iframe style="width: 100%;height: 100%;" allow="camera *; microphone *" :allowfullscreen="true"
                      :src="`https://camera.xuruidea.com/index.html?list=${encodeURIComponent(JSON.stringify(videoList))}&num=${1}&width=${500/1920*windowWidth}&height=${470/1080*(windowHeight/100*90)}`"
                      frameborder="0"></iframe>
                  </div>

                  <!-- 第二 -->
                  <div class="video111" v-if="videoList1 == undefined" id="video-2">
                    <div class="flex flex-a-c flex-j-c" style="width: 100%; height: 100%">
                      <img src="../../assets/images/index/video.png"
                        style="width: calc(330 / 1920 * 100vw);height: calc(329 / 1080 * 100vh);" />
                    </div>
                  </div>
                  <div v-else class="video111" id="video-2">
                    <iframe style="width: 100%;height: 100%;" allow="camera *; microphone *" :allowfullscreen="true"
                      :src="`https://camera.xuruidea.com/index.html?list=${encodeURIComponent(JSON.stringify(videoList1))}&num=${1}&width=${499/1920*windowWidth}&height=${470/1080*(windowHeight/100*90)}`"
                      frameborder="0"></iframe>
                  </div>
                  <!-- 第三 -->
                  <div class="video111" v-if="videoList2 == undefined" id="video-3">
                    <div class="flex flex-a-c flex-j-c" style="width: 100%; height: 100%">
                      <img src="../../assets/images/index/video.png"
                        style=" width: calc(330 / 1920 * 100vw);height: calc(329 / 1080 * 100vh);" />
                    </div>
                  </div>

                  <div v-else class="video111" id="video-3">
                    <iframe style="width: 100%;height: 100%;" allow="camera *; microphone *" :allowfullscreen="true"
                      :src="`https://camera.xuruidea.com/index.html?list=${encodeURIComponent(JSON.stringify(videoList2))}&num=${1}&width=${499/1920*windowWidth}&height=${470/1080*(windowHeight/100*90)}`"
                      frameborder="0"></iframe>
                  </div>

                  <!-- 第四 -->
                  <div class="video111" v-if="videoList3 == undefined" id="video-4">
                    <div class="flex flex-a-c flex-j-c" style="width: 100%; height: 100%">
                      <img src="../../assets/images/index/video.png"
                        style="width: calc(330 / 1920 * 100vw);height: calc(329 / 1080 * 100vh);" />
                    </div>
                  </div>
                  <div v-else class="video111" id="video-4">
                    <iframe style="width: 100%;height: 100%;" allow="camera *; microphone *" :allowfullscreen="true"
                      :src="`https://camera.xuruidea.com/index.html?list=${encodeURIComponent(JSON.stringify(videoList3))}&num=${1}&width=${499/1920*windowWidth}&height=${470/1080*(windowHeight/100*90)}`"
                      frameborder="0"></iframe>
                  </div>

                  <!-- 第五 -->
                  <div class="video111" v-if="videoList4 == undefined" id="video-5">
                    <div class="flex flex-a-c flex-j-c" style="width: 100%; height: 100%">
                      <img src="../../assets/images/index/video.png"
                        style="width: calc(330 / 1920 * 100vw);height: calc(329 / 1080 * 100vh);" />
                    </div>
                  </div>

                  <div v-else class="video111" id="video-5">
                    <iframe style="width: 100%;height: 100%;" allow="camera *; microphone *" :allowfullscreen="true"
                      :src="`https://camera.xuruidea.com/index.html?list=${encodeURIComponent(JSON.stringify(videoList4))}&num=${1}&width=${499/1920*windowWidth}&height=${470/1080*(windowHeight/100*90)}`"
                      frameborder="0"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="voice-dev" v-show="voiceType"></div>
        </div>
      </div>
    </div>
    <div>
      <!-- 开启闸门 -->
      <openwindowone ref="openwindowoneFlag"></openwindowone>
      <!-- 关闭闸门 -->
      <offwindowone ref="offwindowoneFlag"></offwindowone>
      <!-- 急停申请 -->
      <jitingshenqing ref="offjiting"></jitingshenqing>

      <!--   开启布控   -->
      <startcontrol ref="startcontrolClick"></startcontrol>
      <!--  关闭布控  -->
      <closecontrol ref="closecontrolClick"></closecontrol>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import waterlevelecharts from "../waterlevelecharts1.vue"; // 水位
import openwindowone from "../openwindowone.vue"; // 开启
import startcontrol from "../startcontrol.vue"; // 开启布控
import closecontrol from "../closecontrol.vue"; // 关闭布控
import offwindowone from "../offwindowone.vue"; // 关闭
import jitingshenqing from "../jitingshenqing.vue";
import flow from "../flow.vue"; // 流量
import Titles from "../../components/Titles";
import Titles1 from "../../components/Titles1";
import Anmation from "./Anmation";
import NormalTextTooltipol from "../../components/NormalTextTooltipol.vue";
// 闸门信息 气象信息 接口
import { getSiteByIdList } from "../../api/index";
import { getSitesList, getSitesIdList, getGateMessage } from "../../api/main";
import BtnVideo from "../../components/BtnVideo/index.vue";
import BtnVideo1 from "../../components/BtnVideo1/index.vue";
import BtnVideo2 from "../../components/BtnVideo2/index.vue";
import BtnVideo3 from "../../components/BtnVideo3/index.vue";
import BtnVideo4 from "../../components/BtnVideo4/index.vue";
import axios from "../../common/axiosHttp";
import "../../common/recorder-core.js";
import "../../common/mp3.js";
import "../../common/mp3-engine.js";
import { login } from "../../api/login";
import { request } from "http";

export default {
  components: {
    Titles,
    Titles1,
    Anmation,
    BtnVideo,
    BtnVideo1,
    BtnVideo2,
    BtnVideo3,
    BtnVideo4,
    NormalTextTooltipol,
    waterlevelecharts,
    flow,
    openwindowone,
    offwindowone,
    jitingshenqing,
    startcontrol, // 开启布控
    echarts,
    closecontrol,
  },
  data() {
    Date.prototype.format = function (fmt) {
      var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        S: this.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (this.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    };
    return {
      alarmStatus: "",
      siteItemVow: "",
      srcList: [],
      loading: false,
      gateShow: false, //控制闸门图片
      siteId: "", // 站点id
      siteList: [], //站点列表
      siteName: "", // 战点名称
      options: [], //阀门列表
      size: 10, // 当页显示条数
      page: 1, // 当前页
      num: 0, // 第几个阀门
      deviceAddress: "",
      gateVo: {
        gateHeight: 0,
        gateHeightLimit: 0,
      }, // 闸门信息
      siteItemVo: {}, // 气象信息之类的
      warningLevel: "",
      siteItemVomm: "",
      videoList: {}, // 视频列表
      videoList1: {}, // 视频列表
      videoList2: {}, // 视频列表
      videoList3: {}, // 视频列表
      videoList4: {}, // 视频列表
      videoUrl: [], // 视频地址
      pageSize: 0,
      total: 0, // 视频总数
      // 表单区域
      isMains: "",
      valveForm: {
        value: "",
        valveHeight: "", //阀门高度
      },
      playerInstance: null,
      playerInstance1: null,
      playerInstance2: null,
      playerInstance3: null,
      playerInstance4: null,
      rules: {
        value: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
      },
      voiceType: false,
      iscur: null,
      waterlevelflag: 1,
      calldataTime: "", // 报警时间
      operationdataTime: "", // 操作记录时间
      Operationrecord: 1,
      tableData: [],
      currentData: "", // 当前站点数据
      currentPage1: 1,
      pageSize1: 10,
      obj: {},
      total1: 0, // 视频总数
      // -------操作记录-----
      tableData2: [], // 报警记录
      currentPage2: 1,
      pageSize2: 10,
      total2: 0,
      obj2: {},
      dataTime: "",
      dataTimeList: [
        {
          id: 1,
        },
      ],
      optionsList: null, // 拦河闸数据
      optionsData: null, // 对所有的数据进行一次筛选
      socket: null,
      talkbackNumber: null, // 本地编号
      optionValue: null, // 对讲的编号
      urlSrc: `https://lk.xuruidea.com/webapp/dev.html?a=${localStorage.getItem(
        "talkbackNumber"
      )}&b=null`,
      buttonClose: null,
      shouqi: false, // 通话侧边栏
      datetimer: "", // 处理过的时间
      waterEchart: {
        legend: {
          data: ["闸前水位", "闸后水位"],
        },
        grid: {
          left: 120,
          right: 60,
        },
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "闸前水位",
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            smooth: true,
          },
          {
            name: "闸后水位",
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            smooth: true,
          },
        ],
      },
      flowEchart: {
        legend: {
          data: ["瞬时流量"],
        },
        grid: {
          left: 120,
          right: 60,
        },
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "瞬时流量",
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            smooth: true,
          },
        ],
      },
      optionsDataByid: [],
      obj3: {
        startTime: new Date().format(
          "yyyy-MM-dd" + " " + "00" + ":" + "00" + ":" + "00"
        ),
        endTime: new Date().format(
          "yyyy-MM-dd" + " " + "23" + ":" + "59" + ":" + "59"
        ),
      },
      shouqiflag: true,
      timer: 0,
      clearsetInterval: null,
      Warningwater: "", // 警戒水位
      idList: "",
      idshaixuan: [],
      kaiqi: "",
      operands: "",
      guanbi: "",
      operands1: "", // 关闭的
      tishititle: "",
      videoPageBtn: false,
      currentDatadataTime: "", // 更新时间
      bsTabsShow: true,
      voiceIconShow1: true,
      voiceIconShow2: true,
      voiceIconShow3: true,
      voiceIconShow4: true,
      voiceIconShow5: true,
      typeTrue: false,
      playBackShow: false,
      playBackTimeShow: false,
      timeVal: '',
      playBackShow1: false,
      playBackTimeShow1: false,
      timeVal1: '',
      playBackShow2: false,
      playBackTimeShow2: false,
      timeVal2: '',
      playBackShow3: false,
      playBackTimeShow3: false,
      timeVal3: '',
      playBackShow4: false,
      playBackTimeShow4: false,
      timeVal4: '',
      windowWidth: 1920, //获取屏幕宽度,
      windowHeight: 1080, //获取屏幕高度,
    };
  },
  computed: {},
  watch: {
    // 监听设备宽高
    windowWidth(newW, oldW) {
      let that = this;
      console.log("实时屏幕高度：", newW, that.windowWidth);
    },
    windowHeight(newH, oldH) {
      let that = this;
      console.log("实时屏幕高度：", newH, that.windowHeight);
    },
    // 监听路由变化
    $route: "myroute",
    "$store.state.wsSiteData"(e) {
      console.log('111')
      if (e.id === Number(this.$route.params.siteId)) {
        // if(e.warningLevel==this.siteItemVomm.warningLevel){
        //       // setTimeout(()=>{

        //       // },10000)
        //     }
        this.siteItemVo = { ...e };
        this.isMains = e.isMains;
      }
    },
    warningLevel(val) {
      let docInput1 = document.getElementById("input1");
      switch (val.length) {
        case 1:
          docInput1.style = "width:30px";
          break;
        case 2:
          docInput1.style = "width:40px";
          break;
        case 3:
          docInput1.style = "width:50px";
          break;
        case 4:
          this.$message.error("不要超出输出长度");
          break;
      }
    },
    "$store.state.wsGateData"(e) {
      for (const i of e) {
        if (i.siteId === Number(this.$route.params.siteId)) {
          if (i.num === this.num) {
            this.gateVo = { ...i };
            this.getsitedata();
            // var date = new Date(this.gateVo.dataTime)
            // let yy = date.getFullYear()
            // let mm = date.getMonth() + 1;
            // let dd = date.getDate();
            // let hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            // let mf = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            // let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            // this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
          }
        }
      }
    },
    "$store.state.wslRegimeDto"(e) {
      if (e.deviceAddress == this.deviceAddress) {
        this.siteItemVomm = e.rainfall;
      }
      // if (e.id === Number(this.$route.params.siteId)) {
      //   this.siteItemVo = { ...e };
      //   this.isMains = e.isMains;
      // }
    },
    buttonClose(val, oldVal) {
      if (val !== oldVal) {
        this.optionsData.forEach((item) => {
          if (val == item.talkbackNumber) {
            this.optionValue = val;
          }
        });
        // console.log('inputVal = ' + val + ' , oldValue = ' + oldVal)
        // this.buttonClose = val
      }
    },
    timer: function (newVal, oldVal) {
      if (newVal == 0) {
        clearInterval(this.clearsetInterval);
        this.shouqiflag = true;
        this.shouqi = true;
      }
    },
  },
  created() {
    this.myroute();
    this.Siteinformation();
    this.user();
    // this.getsitedata();

  },
  mounted() {
    this.windowWidth=document.documentElement.clientWidth
    this.windowHeight=document.documentElement.clientHeight
  },
  methods: {
    playBack(val) {
      this.playBackTimeShow = false
      console.log('val :>> ', val);
      this.playBackShow = !this.playBackShow
      if (this.playBackShow) {
        let date = new Date()
        let y = date.getFullYear() + ''
        let m = (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) + ''
        let d = (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) + ''
        let beginTime = y + m + d + '000000'
        let endTime = y + m + d + '235959'
        console.log('beginTime :>> ', beginTime);
        this.playerInstance.stop().then(() => {
          this.playerInstance.play({
            url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.rec?begin=${beginTime}&end=${endTime}`,
            accessToken: val.accessToken
          });
        });
      } else {
        this.playerInstance.stop().then(() => {
          this.playerInstance.play({
            url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.live`,
            accessToken: val.accessToken
          });
        });
      }
    },
    playBackTime(val) {
      this.playBackTimeShow = !this.playBackTimeShow
      let beginTime = ''
      let endTime = ''
      this.timeVal.forEach((e, index) => {
        e.split(' ').forEach((i, idx) => {
          if (idx == 0) {
            i.split('-').forEach(t => {
              if (index == 0) {
                beginTime += t
              } else {
                endTime += t
              }
            })
          }
          if (idx == 1) {
            i.split(':').forEach(t => {
              if (index == 0) {
                beginTime += t
              } else {
                endTime += t
              }
            })
          }
        })
      })
      this.playerInstance.stop().then(() => {
        this.playerInstance.play({
          url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.rec?begin=${beginTime}&end=${endTime}`,
          accessToken: val.accessToken
        });
      });
    },
    playBack1(val) {
      this.playBackTimeShow1 = false
      console.log('val :>> ', val);
      this.playBackShow1 = !this.playBackShow1
      if (this.playBackShow1) {
        this.playerInstance1.stop().then(() => {
          this.playerInstance1.play({
            url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.rec?begin=20220725120000&end=20220725235959`,
            accessToken: val.accessToken
          });
        });
      } else {
        this.playerInstance1.stop().then(() => {
          this.playerInstance1.play({
            url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.live`,
            accessToken: val.accessToken
          });
        });
      }
    },
    playBackTime1(val) {
      this.playBackTimeShow1 = !this.playBackTimeShow1
      let beginTime = ''
      let endTime = ''
      this.timeVal1.forEach((e, index) => {
        e.split(' ').forEach((i, idx) => {
          if (idx == 0) {
            i.split('-').forEach(t => {
              if (index == 0) {
                beginTime += t
              } else {
                endTime += t
              }
            })
          }
          if (idx == 1) {
            i.split(':').forEach(t => {
              if (index == 0) {
                beginTime += t
              } else {
                endTime += t
              }
            })
          }
        })
      })
      this.playerInstance1.stop().then(() => {
        this.playerInstance1.play({
          url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.rec?begin=${beginTime}&end=${endTime}`,
          accessToken: val.accessToken
        });
      });
    },
    playBack2(val) {
      this.playBackTimeShow2 = false
      console.log('val :>> ', val);
      this.playBackShow2 = !this.playBackShow2
      if (this.playBackShow2) {
        this.playerInstance2.stop().then(() => {
          this.playerInstance2.play({
            url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.rec?begin=20220725120000&end=20220725235959`,
            accessToken: val.accessToken
          });
        });
      } else {
        this.playerInstance2.stop().then(() => {
          this.playerInstance2.play({
            url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.live`,
            accessToken: val.accessToken
          });
        });
      }
    },
    playBackTime2(val) {
      this.playBackTimeShow2 = !this.playBackTimeShow2
      let beginTime = ''
      let endTime = ''
      this.timeVal2.forEach((e, index) => {
        e.split(' ').forEach((i, idx) => {
          if (idx == 0) {
            i.split('-').forEach(t => {
              if (index == 0) {
                beginTime += t
              } else {
                endTime += t
              }
            })
          }
          if (idx == 1) {
            i.split(':').forEach(t => {
              if (index == 0) {
                beginTime += t
              } else {
                endTime += t
              }
            })
          }
        })
      })
      this.playerInstance2.stop().then(() => {
        this.playerInstance2.play({
          url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.rec?begin=${beginTime}&end=${endTime}`,
          accessToken: val.accessToken
        });
      });
    },
    playBack3(val) {
      this.playBackTimeShow3 = false
      console.log('val :>> ', val);
      this.playBackShow3 = !this.playBackShow3
      if (this.playBackShow3) {
        this.playerInstance3.stop().then(() => {
          this.playerInstance3.play({
            url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.rec?begin=20220725120000&end=20220725235959`,
            accessToken: val.accessToken
          });
        });
      } else {
        this.playerInstance3.stop().then(() => {
          this.playerInstance3.play({
            url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.live`,
            accessToken: val.accessToken
          });
        });
      }
    },
    playBackTime3(val) {
      this.playBackTimeShow3 = !this.playBackTimeShow3
      let beginTime = ''
      let endTime = ''
      this.timeVal3.forEach((e, index) => {
        e.split(' ').forEach((i, idx) => {
          if (idx == 0) {
            i.split('-').forEach(t => {
              if (index == 0) {
                beginTime += t
              } else {
                endTime += t
              }
            })
          }
          if (idx == 1) {
            i.split(':').forEach(t => {
              if (index == 0) {
                beginTime += t
              } else {
                endTime += t
              }
            })
          }
        })
      })
      this.playerInstance3.stop().then(() => {
        this.playerInstance3.play({
          url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.rec?begin=${beginTime}&end=${endTime}`,
          accessToken: val.accessToken
        });
      });
    },
    playBack4(val) {
      this.playBackTimeShow4 = false
      console.log('val :>> ', val);
      this.playBackShow4 = !this.playBackShow4
      if (this.playBackShow4) {
        this.playerInstance4.stop().then(() => {
          this.playerInstance4.play({
            url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.rec?begin=20220725120000&end=20220725235959`,
            accessToken: val.accessToken
          });
        });
      } else {
        this.playerInstance4.stop().then(() => {
          this.playerInstance4.play({
            url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.live`,
            accessToken: val.accessToken
          });
        });
      }
    },
    playBackTime4(val) {
      this.playBackTimeShow4 = !this.playBackTimeShow4
      let beginTime = ''
      let endTime = ''
      this.timeVal4.forEach((e, index) => {
        e.split(' ').forEach((i, idx) => {
          if (idx == 0) {
            i.split('-').forEach(t => {
              if (index == 0) {
                beginTime += t
              } else {
                endTime += t
              }
            })
          }
          if (idx == 1) {
            i.split(':').forEach(t => {
              if (index == 0) {
                beginTime += t
              } else {
                endTime += t
              }
            })
          }
        })
      })
      this.playerInstance4.stop().then(() => {
        this.playerInstance4.play({
          url: `ezopen://open.ys7.com/${val.videoModel}/${val.channelNo}.rec?begin=${beginTime}&end=${endTime}`,
          accessToken: val.accessToken
        });
      });
    },
    // 鼠标移入vide容器判断是否显示分页切换
    videoMouseover() {
      this.videoPageBtn = true;
    },
    videoMousemove() {
      this.videoPageBtn = false;
    },
    // 倒计时
    countDown() {
      this.timer--;
    },
    // 水位点击时间
    waterlevelClick() {
      this.operationdataTime = "";
      let obj3 = {
        startTime: new Date().format(
          "yyyy-MM-dd" + " " + "00" + ":" + "00" + ":" + "00"
        ),
        endTime: new Date().format(
          "yyyy-MM-dd" + " " + "23" + ":" + "59" + ":" + "59"
        ),
      };
      this.water(this.deviceAddress, obj3);
      this.waterlevelflag = 1;
    },
    //  流量点击事件
    flowClick() {
      this.waterlevelflag = 2;
      this.operationdataTime = "";
    },
    // 水位时间获取
    waterleveldate(val) {
      let obj3 = {
        startTime: val[0],
        endTime: val[1],
      };
      this.obj3 = obj3;
      this.water(this.deviceAddress, this.obj3);
    },
    // 水位
    water(id, val) {
      let biao = echarts.init(document.getElementById("main"));
      // var date = new Date().format('yyyy-MM-dd hh:mm:ss')
      this.$axios
        .get(`/site/findWaterBySite`, {
          params: {
            siteAddress: id,
            ...val,
          },
        })
        .then((res) => {
          let day = [];
          let month = "";
          let gateLevel = [];
          let level = [];
          let year = [];
          let days = [];
          res.data.forEach((v) => {
            day.push(v.day);
            month = v.month;
            gateLevel.push(v.gateLevel);
            level.push(v.level);
            year.push(v.year);
          });
          day.forEach((v) => {
            v = month + "/" + v;
            days.push(v);
          });
          this.waterEchart.xAxis.data = days;
          this.waterEchart.series[0].data = gateLevel;
          this.waterEchart.series[1].data = level;
          biao.setOption(this.waterEchart);
        });
    },
    // 流量时间获取
    floweveldate(val) {
      let obj3 = {
        startTime: val[0],
        endTime: val[1],
      };
      this.obj3 = obj3;
      this.flowList(this.deviceAddress, this.obj3);
    },
    // 流量
    flowList(id, val) {
      console.log(val);
      let biao1 = echarts.init(document.getElementById("flow"));
      this.$axios
        .get(`/site/findTrafficBySite`, {
          params: {
            deviceAddress: id,
            ...val,
          },
        })
        .then((res) => {
          let year = [];
          let month = {};
          let day = [];
          let data = [];
          let days = [];
          res.data.forEach((v) => {
            year.push(v.year);
            month = v.month;
            day.push(v.day);
            data.push(v.sumTraffic);
          });
          day.forEach((v) => {
            v = month + "/" + v;
            days.push(v);
          });
          this.flowEchart.xAxis.data = days;
          this.flowEchart.series[0].data = data;
          biao1.setOption(this.flowEchart);
        });
    },
    //收起
    shouqiClick() {
      this.shouqi = !this.shouqi;
      // this.shouqi = true
    },
    videoZoomOut(index) {
      this.bsTabsShow = !this.bsTabsShow;
      let videoContent = document.querySelector("#video-" + index);
      let videoList = document.querySelector('#videoList')
      console.log('videoList :>> ', videoList);
      videoList.style.overflowY = 'hidden';
      let videoIframe = null;
      if (index == 1) {
        videoIframe = document.querySelector("#EZUIKitPlayer-videoList");
      } else {
        index = index - 1;
        videoIframe = document.querySelector(
          "#EZUIKitPlayer-videoList" + index
        );
      }
      if (!this.bsTabsShow) {
        videoContent.style.width = "100%";
        videoContent.style.height = "100%";
        videoContent.style.position = "absolute";
        videoContent.style.backgroundColor = '#000'
        videoContent.style.zIndex = "10000";
        videoIframe.style.width = "100%";
        videoIframe.style.height = "100%";
      } else {
        videoContent.style.width = "49.5%";
        videoContent.style.height = "390px";
        videoContent.style.position = "";
        videoContent.style.zIndex = "9";
        videoIframe.style.width = "100%";
        videoIframe.style.height = "410px";
      }
    },
    videoVoice(index) {
      if (index == 1) {
        this.voiceIconShow1 = !this.voiceIconShow1
        if (this.voiceIconShow1) {
          this.playerInstance.openSound()
        } else {
          this.playerInstance.closeSound()
        }
      } else if (index == 2) {
        this.voiceIconShow2 = !this.voiceIconShow2
        if (this.voiceIconShow2) {
          this.playerInstance1.openSound()
        } else {
          this.playerInstance1.closeSound()
        }
      } else if (index == 3) {
        this.voiceIconShow3 = !this.voiceIconShow3
        if (this.voiceIconShow3) {
          this.playerInstance2.openSound()
        } else {
          this.playerInstance2.closeSound()
        }
      } else if (index == 4) {
        this.voiceIconShow4 = !this.voiceIconShow4
        if (this.voiceIconShow4) {
          this.playerInstance3.openSound()
        } else {
          this.playerInstance3.closeSound()
        }
      } else if (index == 5) {
        this.voiceIconShow5 = !this.voiceIconShow5
        if (this.voiceIconShow5) {
          this.playerInstance4.openSound()
        } else {
          this.playerInstance4.closeSound()
        }
      }
    },
    // 获取 iframe数据
    loaded() {
      this.$nextTick(() => {
        let that = this;
        // 在外部 Vue 的 window 上添加 postMessage 的监听，并且绑定处理函数 handleMessage
        window.addEventListener("message", function (e) {
          const data = e.data;
          switch (data.cmd) {
            case "guaduan":
              // 业务逻辑
              console.log("挂断---挂断");
              that.timer = 3;
              that.clearsetInterval = setInterval(that.countDown, 1000);
              that.shouqiflag = false;
              that.shouqi = false;
              that.$nextTick(() => {
                that.shouqi = true;
              });

              break;
            case "returnClose":
              // 业务逻辑
              that.buttonClose = data.params.data;
              that.optionsData.forEach((item) => {
                if (that.buttonClose == item.talkbackNumber) {
                  that.optionValue = item.siteName;
                }
              });
              break;
          }
        });
      });
    },
    // 查询闸门、站点信息
    Siteinformation() {
      this.$axios
        .get(`/site/getSiteById`, {
          params: {
            id: this.siteId,
          },
        })
        .then((res) => {
          // console.log('1111111111111111111111111111',res);
          this.siteItemVow = res.data.siteItemVo;
          this.srcList.push(this.siteItemVow.img);
          this.optionsDataByid = res.data.alarmStatus;
          // this.iscur = res.data.siteItemVo.deploymentStatus
          // 布防状态 1开启 2关闭
          var bukong = res.data.siteItemVo.deploymentStatus;
          if (bukong == null) {
            this.iscur = 2;
          } else if (bukong == 1) {
            this.iscur = 1;
          } else if (bukong == 2) {
            this.iscur = 2;
          }
        });
    },
    // 拨号
    dial() {
      console.log(rec);
      if (rec) {
        rec.close();
      }

      rec = Recorder({
        type: "mp3",
        sampleRate: testSampleRate,
        bitRate: testBitRate,
        onProcess: function (
          buffers,
          powerLevel,
          bufferDuration,
          bufferSampleRate,
          newBufferIdx,
          asyncEnd
        ) {
          // wave.input(buffers[buffers.length-1],powerLevel,bufferSampleRate);
        },
        takeoffEncodeChunk: function (chunkBytes) {
          //接管实时转码，推入实时处理
          RealTimeSendTry(chunkBytes, false);
        },
      });

      var t = setTimeout(function () {
        console.info(
          "无法录音：权限请求被忽略（超时假装手动点击了确认对话框）",
          1
        );
      }, 8000);

      rec.open(
        function () {
          //打开麦克风授权获得相关资源
          clearTimeout(t);
          rec.start(); //开始录音

          // wave=Recorder.FrequencyHistogramView({elem:".recwave"});
          RealTimeSendTryReset(); //重置
        },
        function (msg, isUserNotAllow) {
          clearTimeout(t);
          console.info(
            (isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg,
            1
          );
        }
      );
    },
    // 获取节制闸列表
    getList() {
      this.$get(`/site/page`, {
        page: 1,
        size: 9999,
      }).then((res) => {
        if (res.code == 200) {
          this.optionsData = res.data.list;
          this.optionsList = res.data.list;
          this.optionValue = res.data.list[0];
          res.data.list.forEach((item, index) => {
            if (this.siteId == item.id) {
              this.optionValue = item.talkbackNumber;
              this.urlSrc = `https://lk.xuruidea.com/webapp/dev.html?a=${this.talkbackNumber}&b=${this.optionValue}`;
            }
          });
        }
        // console.log(optionsList)
        // this.tableData = res.data.list
        // this.pagination.total = res.data.total
      });
    },
    // date时间
    dateTimerdate(val) {
      let obj = {
        startTime: val[0],
        endTime: val[1],
      };
      this.obj = obj;
      let page = 1;
      this.getperationrecord(page, this.size, this.obj);
    },
    // 开启布控
    startcontrolOn(val) {
      var status = "";
      if (this.iscur == null) {
        status = "关闭";
      } else if (this.iscur == 4) {
        status = "开启";
      } else if (this.iscur == 5) {
        status = "关闭";
      }
      // 开启布控
      this.$refs.startcontrolClick.open(
        val,
        this.siteId,
        status,
        this.valveForm.value
      );
      this.iscur = 1;
    },
    // 关闭布控
    closecontrolOff(val) {
      var status = "";
      if (this.iscur == null) {
        status = "关闭";
      } else if (this.iscur == 4) {
        status = "开启";
      } else if (this.iscur == 5) {
        status = "关闭";
      }
      this.$refs.closecontrolClick.open(
        val,
        this.siteId,
        status,
        this.valveForm.value
      );
      this.iscur = 2;
    },
    //警戒水位修改
    test() {
      axios
        .post(`/site/updateSiteLevel`, {
          id: this.$route.params.siteId,
          warningLevel: this.warningLevel,
        })
        .then((res) => {
          if (res.code == 200) {
            this.getGate();
            this.$message({
              message: res.message,
              type: "success",
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    myroute() {
      this.page = 1;
      // 重新赋值
      this.siteId = this.$route.params.siteId;
      this.getsitedata(this.siteId);
      // console.log()
      // 获取站点名称
      this.getName();
      // 获取阀门列表
      this.ValveList();
      // 获取闸门信息
      this.getGate();
      //  获取节制闸列表
      this.getList();
      //  getwebsocket
      // this.getwebsocket()
      this.talkbackNumber = localStorage.getItem("talkbackNumber");
      var talkbackNumber = localStorage.getItem("talkbackNumber");
      urlSrc: `https://lk.xuruidea.com/webapp/dev.html?a=${talkbackNumber}`;
    },
    // 获取阀门列表
    ValveList() {
      this.videoList = "";
      this.videoList1 = "";
      this.videoList2 = "";
      this.videoList3 = "";
      this.videoList4 = "";
      getSitesIdList({ id: this.siteId }).then((res) => {
        if (this.siteId == 92) {
          console.log(this.siteId)
          this.typeTrue = true
          this.options = res.data;
          // console.log(this.options)
          const video = document.querySelector("#videoList");
          const video1 = document.querySelector("#videoList1");
          const video2 = document.querySelector("#videoList2");
          const video3 = document.querySelector("#videoList3");
          const video4 = document.querySelector("#videoList4");
          if (video) video.innerHTML = "";
          if (video1) video.innerHTML = "";
          if (video2) video.innerHTML = "";
          if (video3) video.innerHTML = "";
          if (video4) video.innerHTML = "";
          this.total = 0;
          // 清空语音 DOM 内容，因为闸门不存在时，不执行获取语音设备的操作
          const voice = document.querySelector("#voice-dev");
          if (voice) voice.innerHTML = "";
          this.voiceType = false;
          if (this.options.length !== 0) {
            this.num = this.options[0].num;
            this.valveForm.value = this.options[0].id;
            this.getGateVoice();
            // 切换闸门信息
            getGateMessage({
              id: this.valveForm.value,
              size: this.size,
              page: this.page,
              num: this.num,
              siteId: this.siteId,
            }).then((res) => {
              this.loading = true;
              // 每次重新清空
              this.videoList = res.data.videoInfo.list[0];
              this.videoList1 = res.data.videoInfo.list[1];
              this.videoList2 = res.data.videoInfo.list[2];
              this.videoList3 = res.data.videoInfo.list[3];
              this.videoList4 = res.data.videoInfo.list[4];
              this.total = res.data.videoInfo.total;
              this.pageSize = res.data.videoInfo.pageSize;
              setTimeout(() => {
                const video = document.querySelector("#videoList");
                const video1 = document.querySelector("#videoList1");
                const video2 = document.querySelector("#videoList2");
                const video3 = document.querySelector("#videoList3");
                const video4 = document.querySelector("#videoList4");
                video.innerHTML = "";
                this.playerInstance = new this.$ezuikit.EZUIKitPlayer({
                  autoplay: true,
                  id: "videoList",
                  header: ["capturePicture", "save"],
                  footer: ["hd", "fullScreen"],
                  accessToken: this.videoList.accessToken,
                  url: this.videoList.videoAddress,
                  template: "security",
                  audio: 0,
                  handleSuccess: res => {
                    _this.playerInstance.closeSound()
                  }
                });
                setTimeout(() => {
                }, 5000);
                video1.innerHTML = "";
                this.playerInstance1 = new this.$ezuikit.EZUIKitPlayer({
                  autoplay: true,
                  id: "videoList1",
                  header: ["capturePicture", "save"],
                  footer: ["hd", "fullScreen"],
                  accessToken: this.videoList1.accessToken,
                  url: this.videoList1.videoAddress,
                  template: "security",
                  audio: 0,
                  handleSuccess: res => {
                    _this.playerInstance1.closeSound()
                  }
                });
                video2.innerHTML = "";
                this.playerInstance2 = new this.$ezuikit.EZUIKitPlayer({
                  autoplay: true,
                  id: "videoList2",
                  header: ["capturePicture", "save"],
                  footer: ["hd", "fullScreen"],
                  accessToken: this.videoList2.accessToken,
                  url: this.videoList2.videoAddress,
                  template: "security",
                  audio: 0,
                  handleSuccess: res => {
                    _this.playerInstance2.closeSound()
                  }
                });

                video3.innerHTML = "";
                this.playerInstance3 = new this.$ezuikit.EZUIKitPlayer({
                  autoplay: true,
                  id: "videoList3",
                  header: ["capturePicture", "save"],
                  footer: ["hd", "fullScreen"],
                  accessToken: this.videoList3.accessToken,
                  url: this.videoList3.videoAddress,
                  template: "security",
                  audio: 0,
                  handleSuccess: res => {
                    _this.playerInstance3.closeSound()
                  }
                });

                video4.innerHTML = "";
                this.playerInstance4 = new this.$ezuikit.EZUIKitPlayer({
                  autoplay: true,
                  id: "videoList4",
                  header: ["capturePicture", "save"],
                  footer: ["hd", "fullScreen"],
                  accessToken: this.videoList4.accessToken,
                  url: this.videoList4.videoAddress,
                  template: "security",
                  audio: 0,
                  handleSuccess: res => {
                    _this.playerInstance4.closeSound()
                  }
                });
                document.getElementById("vTabs").click();
              }, 1500);
              this.loading = false;
            });


          } else {
            this.valveForm.value = "无数据";
          }
        } else {
          this.options = res.data;
          // console.log(this.options)
          const video = document.querySelector("#videoList");
          const video1 = document.querySelector("#videoList1");
          const video2 = document.querySelector("#videoList2");
          const video3 = document.querySelector("#videoList3");
          const video4 = document.querySelector("#videoList4");
          if (video) video.innerHTML = "";
          if (video1) video.innerHTML = "";
          if (video2) video.innerHTML = "";
          if (video3) video.innerHTML = "";
          if (video4) video.innerHTML = "";
          this.total = 0;
          // 清空语音 DOM 内容，因为闸门不存在时，不执行获取语音设备的操作
          const voice = document.querySelector("#voice-dev");
          if (voice) voice.innerHTML = "";
          this.voiceType = false;
          if (this.options.length !== 0) {
            this.num = this.options[0].num;
            this.valveForm.value = this.options[0].id;
            this.getGateVoice();
            // 切换闸门信息
            getGateMessage({
              id: this.valveForm.value,
              size: this.size,
              page: this.page,
              num: this.num,
              siteId: this.siteId,
            }).then((res) => {
              this.loading = true;
              console.log('res :>> ', res);
              // 每次重新清空
              this.videoList = res.data.videoInfo.list[0];
              this.videoList1 = res.data.videoInfo.list[1];
              this.videoList2 = res.data.videoInfo.list[2];
              this.videoList3 = res.data.videoInfo.list[3];
              this.videoList4 = res.data.videoInfo.list[4];
              this.total = res.data.videoInfo.total;
              this.pageSize = res.data.videoInfo.pageSize;

              setTimeout(() => {
                const video = document.querySelector("#videoList");
                const video1 = document.querySelector("#videoList1");
                const video2 = document.querySelector("#videoList2");
                const video3 = document.querySelector("#videoList3");
                const video4 = document.querySelector("#videoList4");
                video.innerHTML = "";
                let _this = this
                this.playerInstance = new this.$ezuikit.EZUIKitPlayer({
                  autoplay: true,
                  id: "videoList",
                  header: ["capturePicture", "save"],
                  footer: ["hd", "fullScreen"],
                  accessToken: this.videoList.accessToken,
                  url: this.videoList.videoAddress,
                  template: "security",
                  audio: 0,
                  handleSuccess: res => {
                    _this.playerInstance.closeSound()
                  }
                });

                video1.innerHTML = "";
                this.playerInstance1 = new this.$ezuikit.EZUIKitPlayer({
                  autoplay: true,
                  id: "videoList1",
                  header: ["capturePicture", "save"],
                  footer: ["hd", "fullScreen"],
                  accessToken: this.videoList1.accessToken,
                  url: this.videoList1.videoAddress,
                  template: "security",
                  audio: 0,
                  handleSuccess: res => {
                    _this.playerInstance1.closeSound()
                  }
                });

                video2.innerHTML = "";
                this.playerInstance2 = new this.$ezuikit.EZUIKitPlayer({
                  autoplay: true,
                  id: "videoList2",
                  header: ["capturePicture", "save"],
                  footer: ["hd", "fullScreen"],
                  accessToken: this.videoList2.accessToken,
                  url: this.videoList2.videoAddress,
                  template: "security",
                  audio: 0,
                  handleSuccess: res => {
                    _this.playerInstance2.closeSound()
                  }
                });

                video3.innerHTML = "";
                this.playerInstance3 = new this.$ezuikit.EZUIKitPlayer({
                  autoplay: true,
                  id: "videoList3",
                  header: ["capturePicture", "save"],
                  footer: ["hd", "fullScreen"],
                  accessToken: this.videoList3.accessToken,
                  url: this.videoList3.videoAddress,
                  template: "security",
                  audio: 0,
                  handleSuccess: res => {
                    _this.playerInstance3.closeSound()
                  }
                });

                video4.innerHTML = "";
                this.playerInstance4 = new this.$ezuikit.EZUIKitPlayer({
                  autoplay: true,
                  id: "videoList4",
                  header: ["capturePicture", "save"],
                  footer: ["hd", "fullScreen"],
                  accessToken: this.videoList4.accessToken,
                  url: this.videoList4.videoAddress,
                  template: "security",
                  audio: 0,
                  handleSuccess: res => {
                    _this.playerInstance4.closeSound()
                  }
                });
                document.getElementById("vTabs").click();
              }, 1500);
              this.loading = false;
            });
          } else {
            this.valveForm.value = "无数据";
          }
        }

      });

      setTimeout(() => {
        _this.playerInstance.closeSound()
        _this.playerInstance1.closeSound()
        _this.playerInstance2.closeSound()
        _this.playerInstance3.closeSound()
        _this.playerInstance4.closeSound()
      }, 5000);
    },
    // 视频页切换
    videoTabs(index) {
      const cesList = document.querySelectorAll("#ces");
      // console.log('cesList=====', cesList[0]);
      const video2 = document.querySelector("#video-2");
      const video3 = document.querySelector("#video-3");
      const video4 = document.querySelector("#video-4");
      const video5 = document.querySelector("#video-5");
      if (index == 1) {
        video2.style.display = "block";
        video3.style.display = "block";
        video4.style.display = "block";
        video5.style.display = "none";
      } else if (index == 2) {
        video2.style.display = "none";
        video3.style.display = "none";
        video4.style.display = "none";
        video5.style.display = "block";
      }
    },
    // 切换闸门
    cutGate(value) {
      const id = value;
      if (this.typeTrue == true) {
        // 获取闸门 num
        for (const i of this.options) {
          if (i.id === id) {
            this.num = i.num;
            i.num > 5 ? (this.gateShow = false) : (this.gateShow = true);
          }
        }
        this.getGateVoice();
        // 切换闸门信息
        this.videoList = "";
        this.videoList1 = "";
        this.videoList2 = "";
        this.videoList3 = "";
        this.videoList4 = "";
        getGateMessage({
          id,
          size: this.size,
          page: 1,
          num: this.num,
          siteId: this.siteId,
        }).then((res) => {
          this.loading = true;
          this.gateVo = {};
          this.gateVo = res.data.gateVo;
          // 每次重新清空
          this.videoList = res.data.videoInfo.list[0];
          this.videoList1 = res.data.videoInfo.list[1];
          this.videoList2 = res.data.videoInfo.list[2];
          this.videoList3 = res.data.videoInfo.list[3];
          this.videoList4 = res.data.videoInfo.list[4];
          this.total = res.data.videoInfo.total;
          this.pageSize = res.data.videoInfo.pageSize;
          setTimeout(() => {
            const video = document.querySelector("#videoList");
            const video1 = document.querySelector("#videoList1");
            const video2 = document.querySelector("#videoList2");
            const video3 = document.querySelector("#videoList3");
            const video4 = document.querySelector("#videoList4");
            video.innerHTML = "";
            this.playerInstance = new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: "videoList",
              header: ["capturePicture", "save"],
              footer: ["hd", "fullScreen"],
              accessToken: this.videoList.accessToken,
              url: this.videoList.videoAddress,
              template: "security",
              audio: 0,
            });

            video1.innerHTML = "";
            this.playerInstance1 = new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: "videoList1",
              header: ["capturePicture", "save"],
              footer: ["hd", "fullScreen"],
              accessToken: this.videoList1.accessToken,
              url: this.videoList1.videoAddress,
              template: "security",
              audio: 0,
            });

            video2.innerHTML = "";
            this.playerInstance2 = new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: "videoList2",
              header: ["capturePicture", "save"],
              footer: ["hd", "fullScreen"],
              accessToken: this.videoList2.accessToken,
              url: this.videoList2.videoAddress,
              template: "security",
              audio: 0,
            });

            video3.innerHTML = "";
            this.playerInstance3 = new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: "videoList3",
              header: ["capturePicture", "save"],
              footer: ["hd", "fullScreen"],
              accessToken: this.videoList3.accessToken,
              url: this.videoList3.videoAddress,
              template: "security",
              audio: 0,
            });

            video4.innerHTML = "";
            this.playerInstance4 = new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: "videoList4",
              header: ["capturePicture", "save"],
              footer: ["hd", "fullScreen"],
              accessToken: this.videoList4.accessToken,
              url: this.videoList4.videoAddress,
              template: "security",
              audio: 0,
            });
            document.getElementById("vTabs").click();
          }, 500);
          this.loading = false;
        });
      } else {
        // 获取闸门 num
        for (const i of this.options) {
          if (i.id === id) {
            this.num = i.num;
            i.num > 5 ? (this.gateShow = false) : (this.gateShow = true);
          }
        }
        this.getGateVoice();
        // 切换闸门信息
        this.videoList = "";
        // this.videoList1 = ''
        // this.videoList2 = ''
        // this.videoList3 = ''
        getGateMessage({
          id,
          size: this.size,
          page: 1,
          num: this.num,
          siteId: this.siteId,
        }).then((res) => {
          this.gateVo = {};
          this.gateVo = res.data.gateVo;

          // 每次重新清空
          this.videoList = res.data.videoInfo.list[0];
          // this.videoList1 = res.data.videoInfo.list[1]
          // this.videoList2 = res.data.videoInfo.list[2]
          // this.videoList3 = res.data.videoInfo.list[3]
          this.total = res.data.videoInfo.total;
          this.pageSize = res.data.videoInfo.pageSize;
          setTimeout(() => {
            const video = document.querySelector("#videoList");

            // const video1 = document.querySelector('#videoList1')
            // const video2 = document.querySelector('#videoList2')
            // const video3 = document.querySelector('#videoList3')
            if (video) video.innerHTML = "";
            // if (video1) video.innerHTML = ''
            // if (video2) video.innerHTML = ''
            // if (video3) video.innerHTML = ''

            video.innerHTML = "";
            this.playerInstance = new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: "videoList",
              header: ["capturePicture", "save"],
              footer: ["hd", "fullScreen"],
              accessToken: this.videoList.accessToken,
              url: this.videoList.videoAddress,
              template: "security",
              audio: 0,
            });

            // 关闭切换闸门所有的重新渲染数据
            // video1.innerHTML = ''
            // this.playerInstance1 = new this.$ezuikit.EZUIKitPlayer({
            //   autoplay: true,
            //   id: 'videoList1',
            //   header: ['capturePicture', 'save'],
            //   footer: ['hd', 'fullScreen'],
            //   accessToken: this.videoList1.accessToken,
            //   url: this.videoList1.videoAddress,
            //   template: 'standard',
            //   audio: 0,
            // })
            //
            // video2.innerHTML = ''
            // this.playerInstance1 = new this.$ezuikit.EZUIKitPlayer({
            //   autoplay: true,
            //   id: 'videoList2',
            //   header: ['capturePicture', 'save'],
            //   footer: ['hd', 'fullScreen'],
            //   accessToken: this.videoList2.accessToken,
            //   url: this.videoList2.videoAddress,
            //   template: 'standard',
            //   audio: 0,
            // })
            //
            // video3.innerHTML = ''
            // this.playerInstance1 = new this.$ezuikit.EZUIKitPlayer({
            //   autoplay: true,
            //   id: 'videoList3',
            //   header: ['capturePicture', 'save'],
            //   footer: ['hd', 'fullScreen'],
            //   accessToken: this.videoList3.accessToken,
            //   url: this.videoList3.videoAddress,
            //   template: 'standard',
            //   audio: 0,
            // })
          }, 500);
        });
      }
    },
    // 拨号切换节制闸
    cutGatelist(val) {
      // console.log('本机：' + this.talkbackNumber)
      // console.log('呼叫设备' + this.optionValue)
      this.urlSrc = `https://lk.xuruidea.com/webapp/dev.html?a=${this.talkbackNumber}&b=${this.optionValue}`;
      var _iframe = window.parent.document.getElementById("show-iframe");
      _iframe.contentWindow.location.reload(true);
    },
    // 获取站点名称
    getName() {
      // 获取列表接口
      getSitesList().then((res) => {
        this.siteList = res.data;
        this.siteList.forEach((item) => {
          if (item.id == this.siteId) {
            // 站点名称赋值
            this.siteName = item.siteName;
            // this.getsitedata();
            this.deviceAddress = item.deviceAddress;
            this.water(item.deviceAddress, this.obj3);
            this.flowList(item.deviceAddress, this.obj3);
            axios
              .get(
                `/rainfallRegime/getRainfallByDevice?deviceAddress=${item.deviceAddress}`
              )
              .then((res) => {
                this.siteItemVomm = res.data;
              });
          }
        });
      });
    },
    // 获取闸门信息
    getGate() {
      getSiteByIdList({ id: this.siteId }).then((res) => {
        // console.log(res)
        if (res.data.gateVo) {
          this.gateVo = res.data.gateVo;
        } else {
          Object.keys(this.gateVo).forEach((value) => {
            this.gateVo[value] = "";
          });
        }
        this.alarmStatus = res.data.alarmStatus;
        this.siteItemVo = res.data.siteItemVo;
        this.warningLevel = res.data.siteItemVo.warningLevel;
        this.warningLevel = Number(res.data.siteItemVo.warningLevel).toFixed(0);
        this.alertPower = res.data.siteItemVo.alertPower;
        this.isMains = res.data.isMains;
      });
    },
    // 获取闸门语音设备信息
    async getGateVoice() {
      try {
        let gateId = null;
        for (const i of this.options) {
          if (i.num === this.num) gateId = i.id;
        }
        // 清空语音 DOM 内容
        const voice = document.querySelector("#voice-dev");
        if (voice) voice.innerHTML = "";
        this.voiceType = false;
        // gateId 不存在时返回
        if (!gateId) return;
        const req = await axios.get("/video/getVideoCall", {
          params: { gateId },
        });
        if (req.data) {
          this.voiceType = true;
          if (voice) {
            new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: "voice-dev",
              accessToken: req.data.accessToken,
              url: req.data.videoAddress,
              template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
              // 视频上方头部控件
              //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
              plugin: ["talk"], // 加载插件，talk-对讲
              // 视频下方底部控件
              footer: ["talk", "broadcast", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
              audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
            });
          }
        }
      } catch (err) {
        this.$message.error(err.message);
      }
    },
    // 开启闸门申请
    startSluice(kaiqi) {
      if (this.valveForm.valveHeight == "") {
        return this.$message.error("请输入开启高度！！");
      }
      let userId = Number(localStorage.getItem("id"));
      var c = "";
      var ccc = this.idList.map((item) => {
        if (userId == item.id) {
          c = item;
        }
      });
      // this.valveForm.valveHeight; // 修改的高度
      // this.gateVo.gateHeight; // 当前的闸门高度
      //
      // if (this.valveForm.valveHeight == 0) {
      //   this.tishititle = "关闭";
      // } else if (this.valveForm.valveHeight > this.gateVo.gateHeight) {
      //   this.tishititle = "开启";
      // } else if (this.valveForm.valveHeight < this.gateVo.gateHeight) {
      //   this.tishititle = "关闭";
      // } else if (this.gateVo.gateHeight == this.valveForm.valveHeight) {
      //   this.$message.error({message: "数值相同将不会进行任何操作"});
      //   return;
      // }

      let valveHeight1 = Number(this.valveForm.valveHeight); // 修改的高度
      let gateHeight1 = Number(this.gateVo.gateHeight); // 当前的闸门高度
      console.log(valveHeight1 > gateHeight1)

      if (valveHeight1 > gateHeight1) {
        this.tishititle = "开启";
      } else if (gateHeight1 < valveHeight1) {
        this.tishititle = "关闭";
      } else {
        this.tishititle = "关闭";
      }

      // if (c.id === userId) {
      //   if (this.valveForm.value != String) {
      //     this.options.forEach((item) => {
      //       if (this.valveForm.value === item.id) {
      //         this.$confirm(
      //           `此操作将会${this.tishititle}闸门, 是否继续?`,
      //           "提示",

      //           {
      //             confirmButtonText: "确定",
      //             cancelButtonText: "取消",
      //             type: "warning",
      //           }
      //         )
      //           .then(() => {
      //             this.kaiqi = kaiqi;
      //             this.operands = item.gateName;
      //             this.openthegate();
      //           })
      //           .catch(() => {
      //             this.$message({
      //               type: "info",
      //               message: "已取消开启",
      //             });
      //           });
      //       }
      //     });
      //   }
      // } else {
      //   this.$message({
      //     message: "您无权限操作闸门",
      //     type: "error",
      //   });
      // }


      if (c.id === userId) {
        if (this.valveForm.value != String) {
          this.options.forEach((item) => {
            if (this.valveForm.value === item.id) {
              this.$confirm(
                `此操作将会${this.tishititle}闸门, 是否继续?`,
                "提示",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }
              )
                .then(() => {
                  this.kaiqi = kaiqi;
                  this.operands = item.gateName;
                  this.openthegate(); // 开启闸门
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消开启",
                  });
                });
            }
          });
        }
      } else {
        this.$message({
          message: "您无权限操作闸门",
          type: "error",
        });
      }
    },
    // 开启闸门
    openthegate() {
      let params = {
        gateHeight: this.valveForm.valveHeight, // 修改闸门高度
        gateId: this.valveForm.value, // 闸门Id
        gateType: this.kaiqi, // 操作类型详解 1开启 2关闭 3急停 4开启布控 5关闭布控
        operands: this.operands, // 操作对象
        operationFront: this.gateVo.gateHeight, // 	操作前状态
        operationAfter: this.valveForm.valveHeight, // 操作后状态
        carryOutType: 2, // 执行类型: 1定时执行 2立即执行
        siteId: this.siteId, // 站点id
        createUser: localStorage.getItem("id"),
        type: 1, // 变更闸门高度
      };
      this.$axios.post(`/applyRecord/insertApplyRecord`, params).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "启动成功",
            type: "success",
          });
          // this.valveForm.valveHeight = ''
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      });
    },
    // 获取用户
    user() {
      this.$axios.get(`/user/page/1/9999`).then((res) => {
        this.idList = res.data.list;
        // res.data.list.forEach((v) => {
        //   console.log(v)
        //   let obj = {}
        //   obj.id = v.id + ''
        //   obj.userName = v.userName
        //   this.idList.push(obj)
        // })
        // console.log(this.idList)
      });
    },
    // 关闭 急停
    stopSluice(val) {
      // 关闭
      let userId = Number(localStorage.getItem("id"));
      let c = "";
      this.idList.forEach((item, index) => {
        if (userId === item.id) {
          c = item.id;
        }
      });

      if (c === userId) {
        if (val == 2) {
          if (this.valveForm.value != String) {
            this.options.forEach((item) => {
              if (this.valveForm.value === item.id) {
                this.guanbi = val;
                this.operands1 = item.gateName;
                this.$confirm("此操作将会关闭闸门, 是否继续?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.guanbi = val;
                    this.operands1 = item.gateName;
                    this.Closethegate();
                  })
                  .catch(() => {
                    this.$message({
                      type: "info",
                      message: "已取消关闭",
                    });
                  });
                // this.$refs.offwindowoneFlag.off(this.options, this.valveForm.valveHeight, this.valveForm.value, this.siteId, val, this.gateVo.gateHeight, item.gateName)
              }
            });
          }
          // 急停
        } else if (val == 3) {
          this.options.forEach((item) => {
            if (this.valveForm.value === item.id) {
              this.guanbi = val;
              this.operands1 = item.gateName;
              this.$confirm("此操作将会急停闸门, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.guanbi = val;
                  this.operands1 = item.gateName;
                  this.Closethegate1();
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消关闭",
                  });
                });
            }
          });
          // if (this.valveForm.value != String) {
          //   this.options.forEach((item) => {
          //     if (this.valveForm.value === item.id) {
          //       this.$refs.offjiting.jiting(
          //         this.options,
          //         this.valveForm.valveHeight,
          //         this.valveForm.value,
          //         this.siteId,
          //         val,
          //         this.gateVo.gateHeight,
          //         item.gateName
          //       )
          //     }
          //   })
          // }
        }
      } else {
        this.$message({
          message: "您没有权限",
          type: "error",
        });
      }

      // if (val == 2) {
      //   if (this.valveForm.value != String) {
      //     this.options.forEach((item) => {
      //       if (this.valveForm.value === item.id) {
      //         this.$refs.offwindowoneFlag.off(this.options, this.valveForm.valveHeight, this.valveForm.value, this.siteId, val, this.gateVo.gateHeight, item.gateName)
      //       }
      //     })
      //   }
      //   // 急停
      // } else if (val == 3) {
      //   if (this.valveForm.value != String) {
      //     this.options.forEach((item) => {
      //       if (this.valveForm.value === item.id) {
      //         this.$refs.offjiting.jiting(this.options, this.valveForm.valveHeight, this.valveForm.value, this.siteId, val, this.gateVo.gateHeight, item.gateName)
      //       }
      //     })
      //   }
      // }
    },
    // 关闭闸门
    Closethegate() {
      let params = {
        gateId: this.valveForm.value, // 闸门Id
        gateType: this.guanbi, // 操作类型详解 1开启 2关闭 3急停 4开启布控 5关闭布控
        operands: this.operands1, // 操作对象
        operationFront: this.gateVo.gateHeight, // 	操作前状态
        siteId: this.siteId, // 站点id
        type: 1, // 变更闸门高度
        createUser: localStorage.getItem("id"),
        carryOutType: 2, // 执行类型: 1定时执行 2立即执行
      };
      this.$axios.post(`/applyRecord/insertApplyRecord`, params).then((res) => {
        if (res.code == 200) {
          this.$message.success({ message: "关闭指令已发送，等待设备响应。" });
        } else {
          this.$message.error({ message: "发送命令失败" });
        }
      });
    },
    // 急停闸门
    Closethegate1() {
      let params = {
        gateId: this.valveForm.value, // 闸门Id
        gateType: this.guanbi, // 操作类型详解 1开启 2关闭 3急停 4开启布控 5关闭布控
        operands: this.operands1, // 操作对象
        operationFront: this.gateVo.gateHeight, // 	操作前状态
        siteId: this.siteId, // 站点id
        type: 1, // 变更闸门高度
        createUser: localStorage.getItem("id"),
        carryOutType: 2, // 执行类型: 1定时执行 2立即执行
      };
      this.$axios.post(`/applyRecord/insertApplyRecord`, params).then((res) => {
        if (res.code == 200) {
          this.$message.success({ message: "急停命令已发送，等待设备响应。" });
        } else {
          this.$message.error({ message: "发送命令失败" });
        }
      });
    },
    // 首页点击菜单跳转获取数渲染
    getsitedata(siteId) {
      let siteIdData = ""
      if (siteId) {
        siteIdData = siteId
      } else {
        siteIdData = this.siteId
      }
      this.currentDatadataTime = ""
      this.$axios
        .get(`/site/findById`, {
          params: {
            id: siteIdData,
          },
        })
        .then((res) => {
          if (res.data) {
            this.currentData = res.data;
            this.currentDatadataTime = res.data.dataTime;
            if (this.siteId == res.data.id) {
              this.optionValue = res.data.talkbackNumber;
              this.urlSrc = `https://lk.xuruidea.com/webapp/dev.html?a=${this.talkbackNumber}&b=${this.optionValue}`;
            }
            var timer = res.data.createTime;
            this.datetimer = timer.substring(0, 10);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.update{
  width:100% !important;
  ::v-deep .el-form-item__label{
    width:25%;
  }
  ::v-deep .el-form-item__content{
    width:75%;
  }
}
.update1{
  width:100% !important;
  ::v-deep .el-form-item__label{
    width:12%;
  }
  ::v-deep .el-form-item__content{
    width:88%;
  }
  ::v-deep .el-input__inner{
    width:90% !important
  }
}
::v-deep .panel-top {
  display: none !important;
}

::v-deep .panel-bottom {
  background-color: #000;
}

.sfullscreen {
  width: 100%;
  height: 785px;
}

::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  opacity: 0.5;
}

::v-deep .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
}

.app {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.redRoom {
  color: red;
  font-size: 18px;
}

.dropline {
  color: #cccccc;
  font-size: 18px;
}

::v-deep.el-table {
  margin-bottom: 15px !important;
}

.active {
  background-color: #0179fe;
  color: #ffffff;
}

.active1 {
  background-color: #ffffff;
  color: #409eff;
  border-right: 1px solid #e9e9e9;
  border-radius: 10px 0px 0px 0px;
}

.active2 {
  background-color: #ffffff;
  color: #409eff;
  border-right: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
}

.box-video {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: black;

  .box-video1 {
    height: 65%;
    display: flex;
    justify-content: space-between;

    .videoOne {
      position: relative;
      width: 100%;
      background: #000;
    }
  }

  .box-video2 {
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    div {
      background: #000;
      width: 32%;
    }
  }

  .box-caldjw {
    display: flex;
    flex-wrap: wrap;
    display: -webkit-flex;
    /* Safari */
    justify-content: space-between;
    height: 90vh;

    .video111 {
      // width: 500px;
      height: 50%;
      width: 49.5%;
      float: left;
      position: relative; // 相对定位
      margin-top: 10px;

      .icoVideo {
        position: absolute; // 绝对定位
        // top: 7px;
        // left: 100px;
        z-index: 9999;

        // ::v-deep.btn-switch {
        //   position: relative;
        //   right: 0px !important;
        //   left: 274px !important;
        // }
      }

      @media screen and(max-width: 1920px) {
        .icoVideo {
          position: absolute;
          bottom: 50px;
          right: 0px;
          z-index: 9999;
        }
      }

      @media screen and(max-width: 1680px) {
        .icoVideo {
          position: absolute;
          bottom: 16px;
          right: 10px;
          z-index: 9999;
        }
      }

      .quanpin {
        position: absolute;
        bottom: 12px;
        z-index: 9999;
        color: #ffff;
        font-size: 13px;
        font-weight: none;
        left: 5px;
        cursor: pointer;
      }
    }

    .video222 {
      width: 500px;
      height: 375px;
      float: right;
      position: relative;

      //background-color: green;
      .icoVideo {
        position: absolute;
        top: 7px;
        left: 100px;
        z-index: 9999;

        ::v-deep.btn-switch {
          position: relative;
          right: 0px !important;
          left: 274px !important;
        }
      }

      @media screen and(max-width: 1920px) {
        .icoVideo {
          position: absolute;
          top: -113px;
          right: 90px;
          z-index: 9999;
        }
      }

      @media screen and(max-width: 1680px) {
        .icoVideo {
          position: absolute;
          top: -157px;
          right: 90px;
          z-index: 9999;
        }
      }
    }

    .video333 {
      width: 500px;
      height: 375px;
      margin-top: 10px;
      float: left;
      position: relative;

      //background-color: red;
      .icoVideo {
        position: absolute;
        top: 7px;
        left: 100px;
        z-index: 9999;

        ::v-deep.btn-switch {
          position: relative;
          right: 0px !important;
          left: 274px !important;
        }
      }

      @media screen and(max-width: 1920px) {
        .icoVideo {
          position: absolute;
          top: -113px;
          right: 90px;
          z-index: 9999;
        }
      }

      @media screen and(max-width: 1680px) {
        .icoVideo {
          position: absolute;
          top: -157px;
          right: 90px;
          z-index: 9999;
        }
      }
    }

    .video444 {
      width: 500px;
      height: 375px;
      margin-top: 10px;
      float: right;
      position: relative;

      //background-color: orange;
      .icoVideo {
        position: absolute;
        top: 7px;
        left: 100px;
        z-index: 9999;

        ::v-deep.btn-switch {
          position: relative;
          right: 0px !important;
          left: 274px !important;
        }
      }

      @media screen and(max-width: 1920px) {
        .icoVideo {
          position: absolute;
          top: -113px;
          right: 90px;
          z-index: 9999;
        }
      }

      @media screen and(max-width: 1680px) {
        .icoVideo {
          position: absolute;
          top: -157px;
          right: 90px;
          z-index: 9999;
        }
      }
    }
  }
}

.StationOne {
  // height: 839px;
  height: 100%;
  overflow: hidden;
  position: relative;

  // 右边侧边栏
  .rightcebian {
    position: fixed;
    right: 20px;
    top: 82%;
    z-index: 999;
    color: black;
    z-index: 999999;

    .youce {
      width: 33px;
      z-index: 999999;
      height: 122px;
      background-color: #3fc169;
      position: fixed;
      display: flex;
      right: 0px;
      align-items: center;
      border-radius: 10px 0px 0px 10px;

      .el-icon-phone-outline {
        font-size: 20px;
        padding-left: 5px;
      }
    }

    .rightOne {
      width: 300px;
      height: 120px;
      border-radius: 10px 0px 0px 10px;
      background-color: #ffffff;
      border: 1px solid #cccccc;

      .selctedclas {
        display: flex;
        align-items: center;
        justify-content: center;

        ::v-deep.el-select {
          margin-top: 10px;
        }
      }

      .banclass {
        width: 90%;
        height: 60px;
        text-align: center;
        margin: 0 auto;
        margin-top: 10px;

        ::v-deep.el-button {
          background-color: #1bbe6a;
          border: none;
          color: #ffffff;
        }
      }
    }
  }

  .StationOne-left {
    width: calc(522 / 1920 * 100vw);

    .FourDo {
      height: calc(356 / 1080 * 100vh);
      margin-bottom: calc(13 / 1080 * 100vh);
    }

    // 闸门信息
    .strobe {
      height: calc(113 / 1080 * 100vh);
      margin-bottom: calc(13 / 1080 * 100vh);

      .strobeBox {
        height: calc(55 / 1080 * 100vh);

        .strobeBox-height {
          width: 50%;
          height: calc(23 / 1080 * 100vh);

          span:nth-child(1) {
            color: #333333;
            text-indent: calc(15 / 1920 * 100vw);
          }

          span:nth-child(2) {
            color: #22ac38;
          }

          span:nth-child(3) {
            color: #333333;
          }
        }

        .strobeBox-headroom {
          width: 50%;
          height: calc(23 / 1080 * 100vh);

          span:nth-child(1) {
            color: #333333;
            text-indent: calc(15 / 1920 * 100vw);
          }

          span:nth-child(2) {
            color: #00a0e9;
          }

          span:nth-child(3) {
            color: #333333;
          }
        }
      }

      // 更新时间
      .update {
        color: #333;
        position: absolute;
        left: calc(260 / 1920 * 100vw);
        top: calc(10 / 1080 * 100vh);
        height: calc(60 / 1080 * 100vh);
        font-weight: bold;
        font-size: calc(16 / 1920 * 100vw);

        .textUpdate {
          padding-top: calc(5 / 1080 * 100vh);
          color: #00a0e9;
          font-weight: bold;
          font-size: calc(16 / 1920 * 100vw);
        }
      }
    }

    //水文，太阳能
    .StationOne-left-bottom {
      .content {
        .left {
          width: calc(252 / 1920 * 100vw);

          // 水文信息
          .hydrology {
            width: 100%;
            height: calc(290 / 1080 * 100vh);
            margin-bottom: calc(13 / 1080 * 100vh);

            .hydrologyBox {

              // 当前水位
              .presentWater {
                height: calc(50 / 1080 * 100vh);

                span:nth-child(1) {
                  color: #666666;
                  text-indent: calc(15 / 1920 * 100vw);
                }

                span:nth-child(2) {
                  color: #3cb24c;
                }

                span:nth-child(3) {
                  color: #333333;
                }
              }

              // 警戒水位
              .warningWater {
                .el-input {

                  //width: calc(80 / 1920 * 100vw);
                  .el-input__inner {
                    width: calc(120 / 1920 * 100vw) !important;
                    height: calc(38 / 1080 * 100vh) !important;
                    color: red !important;
                    font-weight: bold !important;
                    font-size: calc(18 / 1080 * 100vh);
                  }
                }

                height: calc(48 / 1080 * 100vh);

                span:nth-child(1) {
                  color: #999999;
                  text-indent: calc(15 / 1920 * 100vw);
                }

                span:nth-child(2) {
                  color: #0196ff;
                }

                span:nth-child(3) {
                  color: #999999;
                }

                .ccc {
                  width: 60px;

                  ::v-deep .el-input__inner {
                    height: 30px !important;
                  }
                }
              }
            }
          }

          // 气象信息
          .weather {
            position: relative;
            height: calc(160 / 1080 * 100vh);

            .weatherBox {
              height: calc(152 / 1080 * 100vh);

              .weatherItem {
                height: calc(50 / 1080 * 100vh);
                color: #999999;

                span:nth-child(1) {
                  text-indent: calc(15 / 1920 * 100vw);
                }

                span:nth-child(2) {
                  color: #0196ff;
                  font-weight: 600;
                }
              }
            }
          }
        }

        ::v-deep .right {
          width: calc(252 / 1920 * 100vw);

          // 太阳能信息
          .solar {
            width: 100%;
            height: calc(390 / 1080 * 100vh);
            margin-bottom: calc(13 / 1080 * 100vh);

            .solarBox {
              height: calc(342 / 1080 * 100vh);

              .solarItem {
                height: calc(48 / 1080 * 100vh);
                color: #999999;

                span:nth-child(1) {
                  text-indent: calc(15 / 1920 * 100vw);
                }
              }

              .el-input {
                width: calc(80 / 1920 * 100vw);

                .el-input__inner {
                  width: calc(80 / 1920 * 100vw) !important;
                  height: calc(38 / 1080 * 100vh) !important;
                  color: red !important;
                  font-weight: bold !important;
                  font-size: calc(18 / 1080 * 100vh);
                }
              }
            }
          }

          .isMains {
            width: 100%;
            height: calc(290 / 1080 * 100vh);
            margin-bottom: calc(13 / 1080 * 100vh);

            .isMainsBox {
              height: calc(342 / 1080 * 100vh);

              .isMainsItem {
                height: calc(35 / 1080 * 100vh);
                color: #999999;

                span:nth-child(1) {
                  text-indent: calc(15 / 1920 * 100vw);
                }
              }

              .floterrousel {
                width: 100%;
                height: 115px;
                display: flex;
                justify-content: center;
                align-items: center;

                //background-color: red;
                .centerlist {
                  width: 220px;
                  height: 110px;

                  ::v-deep.el-carousel {
                    height: 110px;

                    ::v-deep.el-carousel__container {
                      height: 110px;

                      ::v-deep.carousel__item {
                        height: 110px !important;
                      }
                    }
                  }
                }
              }

              .el-input {
                width: calc(80 / 1920 * 100vw);

                .el-input__inner {
                  width: calc(80 / 1920 * 100vw) !important;
                  height: calc(30 / 1080 * 100vh) !important;
                }
              }
            }
          }

          // 安防报警
          .weather {
            position: relative;
            height: calc(160 / 1080 * 100vh);

            .weatherBox {
              height: calc(152 / 1080 * 100vh);

              .weatherItem {
                height: calc(50 / 1080 * 100vh);
                color: #333333;

                span:nth-child(1) {
                  text-indent: calc(15 / 1920 * 100vw);
                  color: #666666;
                }
              }
            }
          }
        }
      }
    }
  }

  // 监控视频
  .StationOne-right {
    width: calc(1040 / 1920 * 100vw);
    height: calc(960 / 1080 * 100vh);
    box-sizing: border-box;
    margin-left: calc(16 / 1920 * 100vw);

    .video {
      position: relative;
      // margin: calc(11 / 1080 * 100vh) calc(11 / 1920 * 100vw);
      height: calc(100% - calc(72 / 1080 * 100vh));

      #voice-dev {
        position: absolute !important;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;

        ::v-deep iframe {
          display: none;
        }

        ::v-deep #voice-list {
          overflow-y: auto;
        }

        ::v-deep #fullScreen {
          display: none;
        }
      }

      .videoItem {
        position: relative;
        width: 100%;
        height: 100%;

        // background: #000;
        .btn-arrow {
          position: absolute;
          top: 50%;
          display: flex;
          justify-content: space-between;
          width: calc(100% - calc(40 / 1920 * 100vw));
          padding: 0 calc(20 / 1920 * 100vw);
          z-index: 99;

          >i {
            background: #fff;
            color: #000;
            padding: 10px;
            border-radius: 50%;
            cursor: pointer;
            transform: translateY(-50%);

            &:active {
              transform: translateY(-37.5%);
            }
          }
        }
      }
    }

    // 通话
    .yuyin {
      height: 59px;
      color: black;
      z-index: 999;
    }

    ::v-deep .el-pagination {
      margin-top: calc(40 / 1080 * 100vh);
    }
  }

  //  基本信息、远程布控、河道数据
  .Integrationcolumn {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
    flex-wrap: wrap;

    // 基本信息
    .essentialinformation {
      width: 32.7%;
      height: 260px;
      background-color: #ffffff;
      color: black;
      justify-content: center;

      .mainflex {
        width: 100%;

        .leftMain {
          width: 50%;
          height: 215px;
          display: flex;
          justify-content: center;
          align-items: center;

          .leftcarousel {
            width: 230px;
            height: 190px;

            ::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
              width: 10px;
              height: 10px;
              background: transparent;
              border: 1px solid #ffffff;
              border-radius: 50%;
              opacity: 0.5;
            }

            ::v-deep.el-carousel__indicator--horizontal.is-active .el-carousel__button {
              width: 10px;
              height: 10px;
              background: #ffffff;
              border-radius: 50%;
              opacity: 1;
            }

            .el-carousel__item h3 {
              color: #475669;
              font-size: 14px;
              opacity: 0.75;
              line-height: 150px;
              margin: 0;
            }

            .el-carousel__item:nth-child(2n) {
              background-color: #99a9bf;
            }

            .el-carousel__item:nth-child(2n + 1) {
              background-color: #d3dce6;
            }
          }
        }

        .rightMain {
          width: 50%;
          height: 215px;
          display: flex;
          justify-content: center;
          align-items: center;

          .maintext {
            width: 230px;
            height: 190px;
            display: flex;
            //justify-content: center;
            //align-items: center;
            color: #6d6d6d;

            // 左侧文字内容
            .lefttext {
              width: 50%;
              text-align: center;
              margin: 0 auto;

              .Sitename {
                width: 100%;
                height: 70px;
              }

              .River {
                height: 75px;
              }

              .responsibleunit {
                height: 60px;
              }

              .textColor {
                color: #999999;
              }
            }

            .righttext {
              width: 50%;
              text-align: center;
              margin: 0 auto;

              .siteaddress {
                width: 100%;
                height: 70px;
              }

              .constructiontime {
                height: 75px;
              }

              .responsiblepersonnel {
                height: 60px;
              }

              .textColor {
                color: #999999;
              }
            }
          }
        }
      }
    }

    // 远程值守
    .remotewatch {
      width: 32.6%;
      height: 260px;
      background-color: #ffffff;
      margin-left: 15px;

      .titleFlex {
        position: relative;
        height: calc(48 / 1080 * 100vh);

        .line::after {
          content: "";
          width: calc(3 / 1920 * 100vw);
          height: calc(17 / 1080 * 100vh);
          background-color: #3669ab;
          position: absolute;
          left: calc(17 / 1920 * 100vw);
          top: calc(17 / 1080 * 100vh);
        }

        .title-text {
          width: 87%;
          height: 100%;
          text-indent: calc(34 / 1920 * 100vw);
          line-height: calc(48 / 1080 * 100vh);
          color: #333;
        }

        border-bottom: calc(2 / 1080 * 100vh) #2c599b solid;

        .text {
          margin-top: calc(14 / 1080 * 100vh);
          width: calc(80 / 1920 * 100vw);
          // height: calc(24 / 1080 * 100vh);
          // padding: calc(4 / 1920 * 100vw);
          text-align: center;
          // background: #e3f2ff;
          border-radius: 12px;
          line-height: calc(24 / 1080 * 100vh);

          p {
            color: #2c599b;
            font-size: calc(18 / 1920 * 100vw);
            font-weight: bold;
          }
        }

        .message {
          position: relative;
          right: calc(20 / 1920 * 100vw);
          font-weight: bold;
          margin-top: calc(12 / 1080 * 100vh);
          color: #2c599b;

          .big {
            font-size: calc(20 / 1920 * 100vw);
          }

          .small {
            font-weight: normal;
            font-size: calc(16 / 1920 * 100vw);
            color: #2c2a37;
          }
        }

        // 远程值守右边按钮
        .rightbtn {
          width: 60%;
          display: flex;
          align-items: center;
          color: #0179fe;

          .btnflex {
            width: 100%;
            border: 1px solid #0179fe;
            height: 30px;
            border-radius: 5px;
            margin-right: 15px;

            // 开启布控
            .startcontrol {
              width: 50%;
              cursor: pointer;
              display: flex;
              height: 30px;
              justify-content: center;
              line-height: 30px;
            }

            // 关闭布控
            .closecontrol {
              width: 50%;
              height: 30px;
              cursor: pointer;
              display: flex;
              justify-content: center;
              line-height: 30px;
            }
          }
        }
      }

      // 远程值守内容区域
      .remotewatchMain {
        width: 100%;
        display: flex;
        color: black;

        // 地图容器
        .map-content {
          width: 50%;
          height: 216px;
          //background-color: red;
          display: flex;
          align-items: center;
          justify-content: center;

          .map-container {
            width: 230px;
            height: 190px;
            background-color: #cccccc;
          }
        }

        // 报警信息
        .Alarm-information {
          width: 50%;
          height: 216px;
          //background-color: skyblue;
          display: flex;
          align-items: center;
          justify-content: center;

          .information {
            width: 60%;
            text-align: center;
            color: #555555;

            .textHeight {
              font-size: 18px;
              text-align: left;
              line-height: 55px;

              .textspan {
                text-align: left;
                color: #419eff;
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    //  河道信息
    .channeldata {
      width: 32.6%;
      height: 260px;
      background-color: #ffffff;
      margin-left: 15px;
      color: black;

      .TableMain {
        width: 100%;
        height: 200px;
        //background-color: skyblue;

        .tableClass {
          width: 90%;
          //border: 1px solid #cccccc;
          margin: auto;
          line-height: 70px;

          .table-td {
            width: 90px;
            height: 10px;
            background-color: #f9f9f9;
            text-align: center;
            color: #666666;
          }
        }
      }
    }
  }

  // 水位流量统计信息
  .discharge-Statistics {
    width: 100%;
    height: 320px;
    background-color: #ffffff;
    margin-bottom: 20px;
    color: black;
    position: relative;
    border-radius: 10px;

    .headerecharts {
      width: 100%;
      height: 62px;
      display: flex;
      align-items: center;
      background-color: #f9f9f9;
      border-bottom: 1px solid #3669ab;
      border-radius: 10px 10px 0px 0px;
    }

    // 水位
    .waterlevel {
      width: 120px;
      line-height: 61px;
      cursor: pointer;
      display: flex;
      font-size: 17px;
      justify-content: center;
      border-radius: 10px 0px 0px 0px;
      position: relative;

      .soliddlaf {
        position: absolute;
        width: 120px;
        background-color: #ffffff;
        height: 1px;
        bottom: -2px;
      }
    }

    // 流量
    .flowflag {
      width: 120px;
      display: flex;
      line-height: 61px;
      font-size: 17px;
      justify-content: center;
      cursor: pointer;
      position: relative;

      .soliddlaf {
        position: absolute;
        width: 120px;
        background-color: #ffffff;
        height: 1px;
        bottom: -2px;
      }
    }

    // 搜索
    .sousuo {
      position: absolute;
      right: 100px !important;
      height: 35px;
      border-radius: 10px;

      ::v-deep .el-date-editor .el-input__icon {
        left: 2px !important;
        top: 1px;
      }

      ::v-deep.el-date-editor.el-input,
      .el-date-editor.el-input__innere {
        border: 1px solid #cccccc !important;
      }
    }

    .waterlevel-Main {
      color: black;
    }
  }

  // 报警操作记录
  .discharge-Statistics1 {
    width: 100%;
    height: 500px;
    background-color: #ffffff;
    margin-bottom: 60px;
    color: black;
    position: relative;
    border-radius: 10px;

    .headerecharts {
      width: 100%;
      height: 62px;
      display: flex;
      align-items: center;
      background-color: #f9f9f9;
      border-bottom: 1px solid #3669ab;
      border-radius: 10px 10px 0px 0px;
    }

    // 报警记录
    .AlarmJi {
      width: 120px;
      line-height: 61px;
      cursor: pointer;
      display: flex;
      font-size: 17px;
      justify-content: center;
      border-radius: 10px 0px 0px 0px;
      position: relative;

      .soliddlaf {
        position: absolute;
        width: 120px;
        background-color: #ffffff;
        height: 1px;
        bottom: -2px;
      }
    }

    // 操作记录
    .Operationrecord {
      width: 120px;
      display: flex;
      line-height: 61px;
      font-size: 17px;
      justify-content: center;
      cursor: pointer;
      position: relative;

      .soliddlaf {
        position: absolute;
        width: 120px;
        background-color: #ffffff;
        height: 1px;
        bottom: -2px;
      }
    }

    // 搜索
    .sousuo {
      position: absolute;
      right: 50px;
      height: 35px;
      border-radius: 10px;

      ::v-deep .el-date-editor .el-input__icon {
        right: -20px !important;
        top: -1px;
      }

      ::v-deep.el-date-editor.el-input,
      .el-date-editor.el-input__innere {
        border: 1px solid #cccccc !important;
      }
    }

    .waterlevel-Main {
      color: black;
    }
  }
}

// 动画区域
.animation {
  width: 100%;
  height: calc(200 / 1080 * 100vh);
}

// 阀门
.valve {
  color: #333333;

  .el-form {
    // margin-left: calc(30 / 1920 * 100vw);

    .el-form-item {
      margin-bottom: calc(10 / 1080 * 100vh);

      ::v-deep .el-select {
        width: calc(162 / 1920 * 100vw);
        margin-right: calc(21 / 1920 * 100vw);

        .el-input__inner {
          height: calc(38 / 1080 * 100vh) !important;
        }
      }
    }

    .valve-h {
      width: 100%;

      ::v-deep .el-input__inner {
        width: calc(72 / 1920 * 100vw);
        height: calc(38 / 1080 * 100vh);
      }

      ::v-deep .el-form-item__content {
        display: flex;
      }
    }
  }
}

// 急停
.el-button+.el-button {
  margin-left: 0;
}

.el-button {
  background: #d9d9d9;
}

// 启动按钮
.start {
  border-radius: 4px 0 0 4px !important;
}

// 急停按钮
.stop {
  border-radius: 0 4px 4px 0 !important;
}

::v-deep .el-input__icon {
  font-size: calc(10 / 1920 * 100vw) !important;
}

.weatherImg {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #666666;
  font-weight: bold;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}

.table-num {
  ::v-deep .el-input__inner {
    width: 32px;
    font-size: 1.2rem;
    height: 33px;
    color: #ff5955;
    padding: 0;
    padding-left: 3px;
  }
}

.icoVideo {
  position: absolute;
  top: 7px;
  right: 70px;
  z-index: 999;
}

::v-deep .btn-switch {
  // bottom: -40px;
  // right: 110px;
  top: 12px;
  right: 165px;
}

::v-deep .btn-control {
  bottom: 0;
  right: 0;
}

::v-deep .el-form-item__label {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

::v-deep .table-num .el-input__inner {
  font-weight: 600;
}

.Font_style1 {
  font-size: 18px;
  font-weight: 600;
}

.bsTabs {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 18px;
  right: 130px;
  cursor: pointer;
}

.voiceIcon {
  width: 23px;
  height: 23px;
  position: absolute;
  bottom: 12px;
  right: 140px;
  cursor: pointer;
}

::v-deep .audio-controls {
  height: 52px !important;
}

::v-deep .audio-controls .contros {
  display: flex;
  align-items: center;
}

::v-deep .el-date-editor.el-range-editor.el-input__inner {
  width: 100% !important;
}
</style>
