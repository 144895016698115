<template>
  <div>
    <el-dialog
      :close-on-click-modal="false" :visible.sync="dialogVisible" width="40%" top="0vh"
      :center="true" @close="cancel">
      <template slot="title">
        <div style=" font-size: 18px; font-weight: bold; color: #333333">关闭闸门申请</div>
      </template>
      <el-form label-position="top" label-width="100px" :rules="boxrules" :model="boxruleForm" ref="boxForm">
        <div class="Publishtask_center">
          <div class="Publishtask_title">
            <el-form-item label="操作闸门" prop="value">
              <el-select style="width: 100%" v-model="boxruleForm.value" @change="cutGate">
                <el-option v-for="item in gateOption" :key="item.id" :label="item.gateName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <!--          <div class="Publishtask_title">-->
          <!--            <el-form-item label="变更后高度" prop="valHeight">-->
          <!--              <el-input style="width: 80%" v-model="boxruleForm.valHeight" maxlength="20"-->
          <!--                        placeholder="请输入闸门高度"></el-input>-->
          <!--              （cm）-->
          <!--            </el-form-item>-->
          <!--          </div>-->
          <div class="Publishtask_title">
            <el-form-item label="执行方式" class="item_form" prop="parentId">
              <el-radio-group @change="changeTheme" v-model="boxruleForm.parentId">
                <el-radio label="1">定时执行</el-radio>
                <el-radio label="2">立即执行</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="Publishtask_title" v-if="timedstart">
            <el-form-item label="执行时间设置" prop="dataTimer">
              <el-date-picker
                v-model="boxruleForm.dataTimer"
                type="datetime"
                placeholder="选择日期时间"
                clearable
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </div>
        <div class="Publishtask_button">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="boxSave">保存</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getGateMessage } from '../api/main'

export default {
  name: 'openwindowone',
  data () {
    return {
      dialogVisible: false,// 打开窗口
      boxrules: {
        valHeight: [ // 闸门高度
          {
            required: true,
            message: '请输入闸门高度',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 3,
            message: '长度在 1 到 3 个字符',
            trigger: 'blur'
          }
        ],
        dataTimer: [ // 定时执行时间
          {
            required: true,
            message: '请选择执行时间 ',
            trigger: 'blur'
          }
        ],
        parentId: [ // 定时执行 还是 审批之后执行
          {
            required: true,
            message: '请选择所属分组',
            trigger: 'change'
          }
        ],
      },
      boxruleForm: {
        valHeight: '', // 变更高度
        parentId: '', // 定时执行 还是 审批之后执行
        dataTimer: '', // 定时执行时间
        value: '', // 操作闸门id
        siteId: null, // 当前站点id
        guanbi: null, // 开启状态值
        operationFront: null,
        operands: null,
      },
      gateOption: null, // 闸门列表
      timedstart: false, //
    }
  },
  methods: {
    // 打开弹窗
    off (option, valHeight, sluicegate, siteId, val, beforeoperation, operands) {
      this.gateOption = option // 闸门列表
      this.boxruleForm.valHeight = valHeight // 高度
      this.boxruleForm.value = sluicegate // 阀门id
      this.boxruleForm.siteId = siteId // 当前操作对象
      this.boxruleForm.guanbi = val // 关闭状态值
      this.boxruleForm.operands = operands, // 闸门
        this.boxruleForm.beforeoperation = beforeoperation + 'cm' // 操作前
      this.dialogVisible = true
    },
    // 获取定时执行还是审批之后立即执行
    changeTheme (val) {
      if (val == 1) {
        this.timedstart = true
      } else {
        this.timedstart = false
      }
    },
    // 切换闸门
    cutGate (value) {
      console.log(value)
      // this.gateShow
      const id = value
      // 获取闸门 num
      for (const i of this.options) {
        if (i.id === id) {
          this.num = i.num
          i.num > 5 ? (this.gateShow = false) : (this.gateShow = true)
        }
      }
      this.getGateVoice()
      // 切换闸门信息
      getGateMessage({
        id,
        size: this.size,
        page: 1,
        num: this.num,
        siteId: this.siteId,
      }).then((res) => {
        this.gateVo = {}
        this.gateVo = res.data.gateVo
        // 每次重新清空
        this.videoList = res.data.videoInfo.list[0]
        this.total = res.data.videoInfo.total
        this.pageSize = res.data.videoInfo.pageSize
        setTimeout(() => {
          const video = document.querySelector('#videoList')
          if (video) {
            video.innerHTML = ''
            new this.$ezuikit.EZUIKitPlayer({
              autoplay: true,
              id: 'videoList',
              header: ['capturePicture', 'save'],
              footer: ['hd', 'fullScreen'],
              accessToken: this.videoList.accessToken,
              url: this.videoList.videoAddress,
              template: 'simple',
              audio: 0,
            })
          }
        }, 500)
      })
    },
    // 取消
    cancel () {
      this.$refs.boxForm.resetFields()
      this.boxruleForm = {}
      this.dialogVisible = false
    },
    // 保存提交申请
    boxSave () {
      let params = {
        carryOutTime: this.boxruleForm.dataTimer, // 执行时间
        carryOutType: this.boxruleForm.parentId, // 执行类型
        gateId: this.boxruleForm.value, // 闸门Id
        gateType: this.boxruleForm.guanbi, // 操作类型详解 1开启 2关闭 3急停 4开启布控 5关闭布控
        operands: this.boxruleForm.operands, // 操作对象
        operationFront: this.boxruleForm.beforeoperation, // 	操作前状态
        siteId: this.boxruleForm.siteId, // 站点id
        type: 1, // 变更闸门高度
      }
      this.$axios.post(`/applyRecord/insertApplyRecord`, params).then((res) => {
        if (res.code == 200) {
          this.$message.success({ message: '申请成功' })
          this.cancel()
        }
      })
    },
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-date-editor .el-input__inner {
  border: 1px solid #DCDFE6 !important;
  width: 110% !important;
}

/deep/ .el-date-editor .el-input__icon {
  right: -20px !important;
  bottom: -3px;
}

/deep/ .el-date-editor .el-input__suffix {
  display: none !important;
}

/deep/ .el-dialog .el-dialog__body .el-form-item__error {
  left: 0px !important;
}

.Publishtask_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .Publishtask_title {
    padding-top: 10px;
    width: 300px;

    /deep/ .el-form-item {
      width: 100% !important;
      margin: 10px !important;

      .el-form-item__content {
        .el-cascader {
          width: 100%;
        }

        /deep/ .el-form-item__error {
          left: 0 !important;
        }
      }
    }
  }

  .Publishtask_textarea {
    padding-top: 10px;
    width: 100%;
  }
}

.Publishtask_button {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}


</style>
